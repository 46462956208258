<template>
  <div class="show-img" :style="{ cursor: isChangeItemWidth ? 'e-resize' : '' }">
    <a-dropdown :trigger="['contextmenu']">
      <div class="show-img-content">
        <div
          class="show-img-content-item"
          :id="'show-img-content-item-' + showOptionIndex"
          :style="{
            backgroundColor,
            width:
              'calc(' +
              ((showOption.changePer || 100 * (1 / showOptions.length)) + '%') +
              ' -' +
              ' ' +
              (62 / showOptions.length + (showOption.changeWidth || 0)) +
              'px)'
          }"
          v-for="(showOption, showOptionIndex) in showOptions"
          :key="showOptionIndex"
        >
          <div
            class="show-img-top"
            @mousewheel="e => handleMouseWheel(e, showOptionIndex)"
            @drop.stop="e => handleDropTop(e, showOptionIndex, showOption.list)"
            :ref="'show-img-top-' + showOptionIndex"
            @dragenter.prevent
            @dragover.prevent
          >
            <div class="show-img-top-scroll">
              <span
                @click="changeOption(option.value)"
                @dragend="e => dragend(e, option.value)"
                :draggable="true"
                :class="option.checked ? 'show-option show-option-select' : 'show-option'"
                v-for="option in showOption.list"
                :key="option.value"
                :id="option.value"
                >{{ option.title }}</span
              >
              <!--  v-if="isChangeItemWidth" -->
              <span class="show-img-top-width" :style="{ color: backgroundColor == 'black' ? '#fff' : '' }"
                >{{
                  showOption.list.find(option => option.checked) &&
                    showOption.list.find(option => option.checked).manuscriptList[0] &&
                    showOption.list.find(option => option.checked).manuscriptList[0].imgWidth
                }}PX</span
              >
            </div>
            <div v-if="showOptionIndex == showOptions.length - 1" class="show-img-top-right">
              <div class="change-show-img-width" @mousedown="handleRightMouseDown">
                <a-icon type="double-right" />
              </div>
            </div>
          </div>
          <div
            :class="
              isAddAnnotation && showOption.list.find(option => option.checked).isLatest
                ? 'show-img-bottom show-img-bottom-local'
                : isAddAnnotation && !showOption.list.find(option => option.checked).isLatest
                ? 'show-img-bottom show-img-bottom-disabled'
                : 'show-img-bottom'
            "
            @scroll="e => showImgScroll(e, showOption, showOptionIndex)"
            :id="'show-img-bottom-' + showOptionIndex"
            @mousedown.stop="e => showImgMousedown(e, showOption)"
          >
            <div
              :style="
                getMaxShowContentStyle(
                  showOption.list.find(option => option.checked),
                  showOptionIndex
                )
              "
              @contextmenu.stop
              v-if="
                showOption.list.find(option => option.checked) &&
                  showOption.list.find(option => option.checked).manuscriptList.length
              "
            >
              <div
                style="position:relative;line-height:0;"
                v-for="item in showOption.list.find(option => option.checked).manuscriptList"
                :key="item.id + '-' + showOption.list.find(option => option.checked).title"
              >
                <img
                  :ref="item.id + 'showLeftImg'"
                  @dragstart.prevent
                  :id="item.id + 'showLeftImg'"
                  :class="!showOptionIndex ? 'left-show-img show-page-img' : 'show-page-img'"
                  :src="item.filePath"
                  :style="{
                    width: showImgListWidth.length >= 4 ? showImgListWidth : '100%',
                    height: 'auto',
                    filter: !isDragOption ? '' : 'blur(3px)'
                  }"
                />
                <div v-if="selectedPlatte" class="img-border"></div>
              </div>

              <div
                v-if="!isDownLoad && !isChangeItemWidth"
                :style="{
                  backgroundColor,
                  height: `${maxImgHeight - showOption.list.find(option => option.checked).allImgHeight}px`
                }"
              ></div>
              <div
                v-if="
                  showOption.list.find(option => option.checked).manuscriptList &&
                    showOption.list.find(option => option.checked).manuscriptList.length
                "
                class="show-content"
              >
                <div
                  :style="{
                    width: '100%',
                    position: 'relative',
                    height: item.imgHeight && item.imgHeight + 'px',
                    overflow: 'initial',
                    display: 'block'
                  }"
                  class="show-page-content"
                  v-for="(item, itemIndex) in showOption.list.find(option => option.checked).manuscriptList"
                  :key="item.id + 'page'"
                  :id="item.id + 'page'"
                  @click="
                    e => {
                      showClick(
                        e,
                        showOption.list.find(option => option.checked),
                        itemIndex
                      )
                    }
                  "
                >
                  <template
                    v-if="
                      item.annotations &&
                        item.imgHeight &&
                        isShow &&
                        hasLocal &&
                        item.annotations.filter(
                          local =>
                            local.annotationKind == 1 &&
                            (checkedStages.some(c => c == local.targetStage) || !local.targetStage) &&
                            (!radioValue ||
                              (radioValue == 2 && (local.updateStatus == 2 || !local.updateStatus)) ||
                              (radioValue == 1 && local.updateStatus == 1))
                        ).length &&
                        !isDownLoad &&
                        !selectedPlatte &&
                        showOption.list.find(option => option.checked).num
                    "
                  >
                    <vue-draggable-resizable
                      class="ocr-show-page-text"
                      :id="'ocr-show-page-text-' + local.id"
                      :style="{ position: 'absolute', border: 'none' }"
                      :draggable="isSelectedLocal || !changeAll || !local.selected ? false : true"
                      :resizable="false"
                      :parent="true"
                      @deactivated="onDeactivatedAnnotation(local)"
                      @dragging="(left, top) => dragging(left, top, local)"
                      @dragstop="
                        (left, top) => {
                          onDragstopAnnotation(
                            left,
                            top,
                            local,
                            showOption.list.find(option => option.checked),
                            item,
                            itemIndex
                          )
                        }
                      "
                      v-for="(local, aIdx) in item.annotations.filter(
                        local =>
                          local.annotationKind == 1 &&
                          (checkedStages.some(c => c == local.targetStage) || !local.targetStage)
                      )"
                      :x="
                        getAnnotationStyle(
                          local,
                          showOption.list.find(option => option.checked),
                          'right'
                        ).x
                      "
                      :y="
                        getAnnotationStyle(
                          local,
                          showOption.list.find(option => option.checked),
                          'right'
                        ).y
                      "
                      :w="
                        getAnnotationStyle(
                          local,
                          showOption.list.find(option => option.checked),
                          'right'
                        ).w
                      "
                      :h="
                        getAnnotationStyle(
                          local,
                          showOption.list.find(option => option.checked),
                          'right'
                        ).h
                      "
                      :z="local.selected ? 98 : 97"
                      :key="aIdx + 'imgLocal'"
                    >
                      <div
                        @click.stop="
                          onActivatedAnnotation(
                            local,
                            showOption.list.find(option => option.checked)
                          )
                        "
                        :id="local.id ? 'local-' + local.id : 'newLocal-' + aIdx"
                      >
                        <local-two
                          theme="filled"
                          :size="local.size || 30"
                          :fill="local.selected && selectLocalId == local.id ? color : 'orange'"
                          :strokeWidth="3"
                        />
                      </div>
                      <div
                        @mouseover.stop="isSelectedLocal = true"
                        @mouseleave.stop="isSelectedLocal = false"
                        click.stop
                        :style="
                          getLocalActionStyle(
                            item,
                            showOption.list.find(option => option.checked),
                            'left',
                            local
                          )
                        "
                        class="local-content"
                        v-if="local.selected"
                      >
                        <div
                          v-if="
                            local.toStagesLeaveMessage &&
                              local.toStagesLeaveMessage.length &&
                              ((local.id + '').indexOf('newLocal') > -1 || local.initUser.userId == userInfo.id)
                          "
                          class="local-content-to-stage"
                        >
                          <div style="font-size:16px;height:100%">
                            To:
                          </div>
                          <div style="margin-left:10px;">
                            <a-radio-group
                              :disabled="isAutoSave || contentEdit"
                              @change="
                                toStageChange(
                                  local,
                                  item,
                                  itemIndex,
                                  showOption.list.find(option => option.checked),
                                  1
                                )
                              "
                              v-model="local.targetStage"
                            >
                              <a-radio
                                :value="stage.stageName"
                                v-for="stage in local.toStagesLeaveMessage.filter(s => {
                                  return (changeAll && !contentEdit) || s.stageName == local.targetStage
                                })"
                                :key="stage.stageName"
                              >
                                {{ stage.stageName }}
                              </a-radio>
                            </a-radio-group>
                          </div>
                        </div>
                        <div
                          class="local-content-list"
                          :style="{
                            padding: local.commentData && local.commentData.length ? '' : '0'
                          }"
                        >
                          <!-- taskDetail.stageName != '校对' &&
                                taskDetail.beingTested &&
                                !taskDetail.rejectTaskId &&
                                local.toStagesAnnotation.find(s => s.stageName == local.targetStage).isShow
                             -->
                          <div v-if="false" class="local-content-error-title">
                            软硬伤标记
                          </div>
                          <div v-if="false" class="local-content-error-select">
                            <div style="display:flex;align-items:center">
                              <div
                                @click.stop="
                                  changeAll && !contentEdit
                                    ? selectLocalError(
                                        2,
                                        local,
                                        item,
                                        itemIndex,
                                        showOption.list.find(option => option.checked)
                                      )
                                    : () => {}
                                "
                                :class="local.injuryType == 2 ? 'platte-error platte-error-select' : 'platte-error'"
                                style="color:red;width: 28px;padding-left: 6px;"
                              >
                                <span>硬<a-icon v-if="local.injuryType == 2" type="check-circle" theme="filled"/></span>
                              </div>
                              <div
                                v-if="local.targetStage != '监制' && taskDetail.stageName != '监制确认'"
                                @click.stop="
                                  changeAll && !contentEdit
                                    ? selectLocalError(
                                        1,
                                        local,
                                        item,
                                        itemIndex,
                                        showOption.list.find(option => option.checked)
                                      )
                                    : () => {}
                                "
                                :class="local.injuryType == 1 ? 'platte-error platte-error-select' : 'platte-error'"
                                style="color:orange;"
                              >
                                <span>软<a-icon v-if="local.injuryType == 1" type="check-circle" theme="filled"/></span>
                              </div>
                              <div
                                v-if="
                                  local.targetStage != '监制' &&
                                    local.targetStage != '校对' &&
                                    taskDetail.stageName != '监制确认'
                                "
                                @click.stop="
                                  changeAll && !contentEdit
                                    ? selectLocalError(
                                        4,
                                        local,
                                        item,
                                        itemIndex,
                                        showOption.list.find(option => option.checked)
                                      )
                                    : () => {}
                                "
                                :class="local.injuryType == 4 ? 'platte-error platte-error-select' : 'platte-error'"
                                style="color:red;"
                              >
                                <span style="width:35px"
                                  >超硬<a-icon v-if="local.injuryType == 4" type="check-circle" theme="filled"
                                /></span>
                              </div>
                              <div
                                v-if="
                                  local.targetStage != '监制' &&
                                    local.targetStage != '校对' &&
                                    taskDetail.stageName != '监制确认'
                                "
                                @click.stop="
                                  changeAll && !contentEdit
                                    ? selectLocalError(
                                        5,
                                        local,
                                        item,
                                        itemIndex,
                                        showOption.list.find(option => option.checked)
                                      )
                                    : () => {}
                                "
                                :class="local.injuryType == 5 ? 'platte-error platte-error-select' : 'platte-error'"
                                style="color:orange;margin-left:8px"
                              >
                                <span style="width:35px"
                                  >超软<a-icon v-if="local.injuryType == 5" type="check-circle" theme="filled"
                                /></span>
                              </div>
                            </div>
                            <div
                              v-if="local.injuryType"
                              @mouseover.stop="isSelectedLocal = true"
                              @mouseleave.stop="isSelectedLocal = false"
                              style="padding-left:9px;padding-bottom:12px"
                            >
                              {{ local.injuryReason }}
                            </div>
                          </div>
                          <div class="local-content-error-list" v-if="local.commentData.length">
                            <div
                              class="local-content-error-list-item"
                              v-for="(localItem, localItemIndex) in local.commentData"
                              :key="localItem.id"
                              :style="{ paddingBottom: localItemIndex != local.commentData.length - 1 ? '5px' : '' }"
                            >
                              <div class="local-content-error-list-item-top">
                                <div class="local-content-error-list-item-top-left">
                                  <img :src="localItem.avatar" />
                                </div>
                                <div class="local-content-error-list-item-top-right">
                                  <div style="font-size:12px">{{ localItem.userName || '用户名' }}</div>
                                  <div style="font-size:12px">{{ localItem.createTimeStr }}</div>
                                </div>
                              </div>
                              <div class="local-content-error-list-item-bottom">
                                <div class="local-content-error-list-item-bottom-left"></div>
                                <div class="local-content-error-list-item-bottom-right">
                                  <div
                                    class="show-local-content"
                                    @copy="textCopy"
                                    v-html="localItem.content || '无说明'"
                                    style="font-size:12px;color:black;min-height:18px;user-select: text;"
                                  ></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="local-content-input"
                          @mouseover.stop="isSelectedLocal = true"
                          @mouseleave.stop="isSelectedLocal = false"
                          v-if="local.selected"
                        >
                          <j-editor
                            v-if="changeAll && !contentEdit && selectLocalId == local.id"
                            ref="localInput"
                            @mouseover.stop="isSelectedLocal = true"
                            @postValue="
                              saveLoacal(
                                local,
                                item,
                                itemIndex,
                                showOption.list.find(option => option.checked)
                              )
                            "
                            :placeholder="'ALT+回车换行，回车提交'"
                            v-model="localValue"
                            :maxWidth="'50px'"
                            :toolbar="[]"
                          ></j-editor>
                        </div>
                      </div>
                      <div
                        v-if="
                          local.selected &&
                            selectLocalId == local.id &&
                            changeAll &&
                            (isLocalZhongShen ||
                              checkPermission('fatchStraw:localList:dele') ||
                              (local.initUser && userInfo.id == local.initUser.userId) ||
                              (local.id + '').indexOf('newLocal') > -1 ||
                              (local.targetStage == '终审' &&
                                local.createStage == '监制' &&
                                userInfo.positions.some(item => item.name == '终审')))
                        "
                        class="ocr-show-item-title-local"
                        :style="getActionCloseStyle(showOption.list.find(option => option.checked).num)"
                      >
                        <!-- 终审可以删除监制给自己的批注，判断当前阶段是否是终审，登陆人员是否包含终审职位，是否是监制给终审的批注,陈立说的 -->
                        <span></span>
                        <div>
                          <a-icon type="close" @click.stop="deleteLocal(local, item, aIdx, item.annotations)" />
                        </div>
                      </div>
                    </vue-draggable-resizable>
                  </template>
                  <template
                    v-if="item.annotations && !selectedPlatte && showOption.list.find(option => option.checked).num"
                  >
                    <img
                      v-for="local in item.annotations.filter(
                        local =>
                          local.annotationKind != 1 &&
                          local.annotationKind != 3 &&
                          (checkedStages.some(c => c == local.targetStage) || !local.targetStage) &&
                          (!radioValue ||
                            (radioValue == 2 && (local.updateStatus == 2 || !local.updateStatus)) ||
                            (radioValue == 1 && local.updateStatus == 1))
                      )"
                      @dragstart.prevent
                      :key="local.id"
                      :src="local.imageUrl"
                      :style="{
                        width: local.position.width
                          ? local.position.width * showOption.list.find(option => option.checked).num + 'px'
                          : '100%',
                        height: 'auto',
                        position: 'absolute',
                        opacity: selectLocalId == local.id || selectLocalId == -1 ? '1' : '0.3',
                        top: local.position.y * showOption.list.find(option => option.checked).num + 'px',
                        left: 0
                      }"
                    />
                    <div
                      v-for="local in item.annotations.filter(
                        local =>
                          local.annotationKind == 3 &&
                          (checkedStages.some(c => c == local.targetStage) || !local.targetStage) &&
                          (!radioValue ||
                            (radioValue == 2 && (local.updateStatus == 2 || !local.updateStatus)) ||
                            (radioValue == 1 && local.updateStatus == 1))
                      )"
                      :key="local.id"
                      @click.stop="
                        onActivatedAnnotation(
                          local,
                          showOption.list.find(option => option.checked)
                        )
                      "
                      :style="{
                        width: local.position.width * showOption.list.find(option => option.checked).num + 'px',
                        height: local.position.height * showOption.list.find(option => option.checked).num + 'px',
                        position: 'absolute',
                        border: selectLocalId == local.id ? '2px solid red' : '2px solid orange',
                        top: local.position.y * showOption.list.find(option => option.checked).num + 'px',
                        left: local.position.x * showOption.list.find(option => option.checked).num + 'px'
                      }"
                    >
                      <div
                        @mouseover.stop="isSelectedLocal = true"
                        @mouseleave.stop="isSelectedLocal = false"
                        :style="
                          getLocalActionStyle(
                            item,
                            showOption.list.find(option => option.checked),
                            'left',
                            local
                          )
                        "
                        class="local-content"
                        v-if="local.selected && selectLocalId == local.id"
                      >
                        <div
                          class="local-content-list"
                          :style="{
                            padding: local.commentData && local.commentData.length ? '0' : '0'
                          }"
                        >
                          <div
                            v-if="
                              local.toStagesAnnotation &&
                                local.toStagesAnnotation.length &&
                                ((local.id + '').indexOf('newLocal') > -1 || local.initUser.userId == userInfo.id)
                            "
                            class="local-content-to-stage"
                          >
                            <span style="font-size:16px;height:100%">
                              To:
                            </span>
                            <div style="margin-left:10px;">
                              <a-radio-group
                                v-model="local.targetStage"
                                :disabled="!changeAll || isAutoSave || contentEdit"
                                @change="
                                  toStageChange(
                                    local,
                                    item,
                                    itemIndex,
                                    showOption.list.find(option => option.checked),
                                    3
                                  )
                                "
                              >
                                <a-radio
                                  :value="stage.stageName"
                                  v-for="stage in local.toStagesAnnotation.filter(s => {
                                    return (changeAll && !contentEdit) || s.stageName == local.targetStage
                                  })"
                                  :key="stage.stageName"
                                >
                                  {{ stage.stageName }}
                                </a-radio>
                              </a-radio-group>
                            </div>
                          </div>
                          <div
                            class="local-content-error-title"
                            v-if="
                              local.toStagesAnnotation.find(stage => stage.stageName == local.targetStage) &&
                                local.toStagesAnnotation.find(stage => stage.stageName == local.targetStage).errorDict
                            "
                          >
                            软硬伤标记
                          </div>
                          <div
                            class="local-content-error-select"
                            v-if="
                              local.toStagesAnnotation.find(stage => stage.stageName == local.targetStage) &&
                                local.toStagesAnnotation.find(stage => stage.stageName == local.targetStage).errorDict
                            "
                          >
                            <div style="display:flex;align-items: center;">
                              <div
                                @click.stop="
                                  changeAll && !contentEdit
                                    ? selectLocalError(
                                        0,
                                        local,
                                        item,
                                        itemIndex,
                                        showOption.list.find(option => option.checked),
                                        3
                                      )
                                    : () => {}
                                "
                                :class="local.injuryType == 0 ? 'platte-error platte-error-select' : 'platte-error'"
                                style="width: 28px;padding-left: 6px;"
                              >
                                <span>无<a-icon v-if="!local.injuryType" type="check-circle" theme="filled"/></span>
                              </div>
                              <div
                                @click.stop="
                                  changeAll && !contentEdit
                                    ? selectLocalError(
                                        error.type,
                                        local,
                                        item,
                                        itemIndex,
                                        showOption.list.find(option => option.checked),
                                        3
                                      )
                                    : () => {}
                                "
                                v-for="(error, errorIndex) in local.toStagesAnnotation.find(
                                  stage => stage.stageName == local.targetStage
                                ).errorDict"
                                :key="error.id"
                                :class="
                                  local.injuryType == error.type ? 'platte-error platte-error-select' : 'platte-error'
                                "
                                :style="{
                                  color:
                                    error.dictName.indexOf('硬') > -1 || error.dictName.indexOf('错翻') > -1
                                      ? 'red'
                                      : 'orange',
                                  justifyContent: 'center',
                                  width: error.dictName.length == 1 ? '28px' : '40px',
                                  paddingLeft: !errorIndex ? '2px' : ''
                                }"
                              >
                                <span :style="{ width: error.dictName.length > 1 ? '40px' : '', textAlign: 'center' }"
                                  >{{ error.dictName
                                  }}<a-icon v-if="local.injuryType == error.type" type="check-circle" theme="filled"
                                /></span>
                              </div>
                              <!--                               <div
                                v-if="local.targetStage != '监制' && taskDetail.stageName != '监制确认'"
                                @click.stop="
                                  changeAll && !contentEdit
                                    ? selectLocalError(
                                        1,
                                        local,
                                        item,
                                        itemIndex,
                                        showOption.list.find(option => option.checked),
                                        3
                                      )
                                    : () => {}
                                "
                                :class="local.injuryType == 1 ? 'platte-error platte-error-select' : 'platte-error'"
                                style="color:orange"
                              >
                                <span>软<a-icon v-if="local.injuryType == 1" type="check-circle" theme="filled"/></span>
                              </div>
                              <div
                                v-if="
                                  local.targetStage != '监制' &&
                                    local.targetStage != '校对' &&
                                    taskDetail.stageName != '监制确认'
                                "
                                @click.stop="
                                  changeAll && !contentEdit
                                    ? selectLocalError(
                                        4,
                                        local,
                                        item,
                                        itemIndex,
                                        showOption.list.find(option => option.checked),
                                        3
                                      )
                                    : () => {}
                                "
                                :class="local.injuryType == 4 ? 'platte-error platte-error-select' : 'platte-error'"
                                style="color:red;"
                              >
                                <span style="width:35px"
                                  >超硬<a-icon v-if="local.injuryType == 4" type="check-circle" theme="filled"
                                /></span>
                              </div>
                              <div
                                v-if="
                                  local.targetStage != '监制' &&
                                    local.targetStage != '校对' &&
                                    taskDetail.stageName != '监制确认'
                                "
                                @click.stop="
                                  changeAll && !contentEdit
                                    ? selectLocalError(
                                        5,
                                        local,
                                        item,
                                        itemIndex,
                                        showOption.list.find(option => option.checked),
                                        3
                                      )
                                    : () => {}
                                "
                                :class="local.injuryType == 5 ? 'platte-error platte-error-select' : 'platte-error'"
                                style="color:orange;margin-left:8px"
                              >
                                <span style="width:35px"
                                  >超软<a-icon v-if="local.injuryType == 5" type="check-circle" theme="filled"
                                /></span>
                              </div> -->
                            </div>
                            <div
                              v-if="local.injuryType"
                              @mouseover.stop="isSelectedLocal = true"
                              @mouseleave.stop="isSelectedLocal = false"
                              style="padding-left:9px;padding-bottom:12px"
                            >
                              {{ local.injuryReason }}
                            </div>
                          </div>
                          <div
                            v-if="
                              (local.commentData && local.commentData.length > 1) ||
                                (local.commentData.length == 1 && local.commentData[0].content)
                            "
                            class="local-content-error-list"
                          >
                            <div
                              class="local-content-error-list-item"
                              v-for="(localItem, localItemIndex) in local.commentData"
                              :key="localItem.id"
                              :style="{ paddingBottom: localItemIndex != local.commentData.length - 1 ? '5px' : '' }"
                            >
                              <div class="local-content-error-list-item-top">
                                <div class="local-content-error-list-item-top-left">
                                  <img :src="localItem.avatar" style="width: 20px;height: 20px;border-radius: 50%;" />
                                </div>
                                <div class="local-content-error-list-item-top-right">
                                  <div style="font-size:12px">{{ localItem.userName || '用户名' }}</div>
                                  <div style="font-size:12px">{{ localItem.createTimeStr }}</div>
                                </div>
                              </div>
                              <div class="local-content-error-list-item-bottom">
                                <div class="local-content-error-list-item-bottom-left"></div>
                                <div class="local-content-error-list-item-bottom-right">
                                  <div
                                    class="show-local-content"
                                    @copy="textCopy"
                                    v-html="localItem.content || '无说明'"
                                    style="font-size:12px;color:black;min-height:18px;user-select: text;"
                                  ></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="local-content-input"
                          @mouseover.stop="isSelectedLocal = true"
                          @mouseleave.stop="isSelectedLocal = false"
                        >
                          <j-editor
                            v-if="changeAll && !contentEdit"
                            ref="localInput"
                            @mouseover.stop="isSelectedLocal = true"
                            @postValue="
                              saveLoacal(
                                local,
                                item,
                                itemIndex,
                                showOption.list.find(option => option.checked),
                                3
                              )
                            "
                            :placeholder="'ALT+回车换行，回车提交'"
                            v-model="localValue"
                            :maxWidth="'50px'"
                            :toolbar="[]"
                          ></j-editor>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template
                    v-if="
                      diffTitle &&
                        !selectedPlatte &&
                        !isDownLoad &&
                        diffTitle == showOption.list.find(option => option.checked).title &&
                        diffs.length &&
                        diffs.find(diff => diff.fileName == item.fileName) &&
                        diffs.find(diff => diff.fileName == item.fileName).rectangles != null
                    "
                  >
                    <template
                      v-for="(diffItem, diffIndex) in diffs.find(diff => diff.fileName == item.fileName).rectangles"
                    >
                      <div
                        :ref="diffItem.minPoint.y + '' + diffIndex"
                        :id="diffs.findIndex(diff => diff.fileName == item.fileName) + '-' + diffIndex + '-diff-item'"
                        :key="diffIndex + '-diff-div'"
                        :class="
                          selectedDiffIdx &&
                          diffs[selectedDiffIdx.split('-')[0]].fileName == item.fileName &&
                          selectedDiffIdx.split('-')[1] == diffIndex
                            ? 'diff-div-select'
                            : 'diff-div'
                        "
                        :style="
                          `left:${diffItem.minPoint.x *
                            showOption.list.find(option => option.checked).num}px;top:${diffItem.minPoint.y *
                            showOption.list.find(option => option.checked).num}px;width:${(diffItem.maxPoint.x -
                            diffItem.minPoint.x) *
                            showOption.list.find(option => option.checked).num}px;height:${(diffItem.maxPoint.y +
                            20 -
                            diffItem.minPoint.y) *
                            showOption.list.find(option => option.checked).num}px;min-width:10px;min-height:10px;${
                            showDiffs == 'ON' ? '' : 'opacity:0;'
                          }${!diffItem.isShow ? '' : 'opacity:0;'}`
                        "
                        @click.stop="
                          selectDiff(
                            diffIndex,
                            diffs.findIndex(diff => diff.fileName == item.fileName)
                          )
                        "
                      >
                        <div
                          @mouseover.stop="isSelectedLocal = true"
                          @mouseleave.stop="isSelectedLocal = false"
                          @click.stop
                          :style="
                            getLocalActionStyle(
                              item,
                              showOption.list.find(option => option.checked),
                              'left',
                              { position: { x: diffItem.minPoint.x, y: diffItem.minPoint.y } }
                            )
                          "
                          class="local-content"
                          v-if="
                            selectedDiffIdx &&
                              showOption.list.find(option => option.checked).isLatest &&
                              diffs[selectedDiffIdx.split('-')[0]].fileName == item.fileName &&
                              selectedDiffIdx.split('-')[1] == diffIndex
                          "
                        >
                          <div
                            class="local-content-list"
                            :style="{
                              padding: diffItem.commentData && diffItem.commentData.length ? '' : '0'
                            }"
                          >
                            <div v-if="toStagesAnnotation && toStagesAnnotation.length" class="local-content-to-stage">
                              <div style="font-size:16px;height:100%">
                                To:
                              </div>
                              <div style="margin-left:10px;">
                                <a-radio-group
                                  v-model="targetStage"
                                  :disabled="!changeAll || isAutoSave || contentEdit"
                                  @change="
                                    toStageChangeDiff(
                                      diffItem,
                                      item,
                                      itemIndex,
                                      showOption.list.find(option => option.checked),
                                      diffIndex
                                    )
                                  "
                                >
                                  <a-radio
                                    :value="stage.stageName"
                                    v-for="stage in toStagesAnnotation.filter(s => {
                                      return (changeAll && !contentEdit) || s.stageName == targetStage
                                    })"
                                    :key="stage.stageName"
                                  >
                                    {{ stage.stageName }}
                                  </a-radio>
                                </a-radio-group>
                              </div>
                            </div>
                            <div
                              class="local-content-error-title"
                              v-if="
                                toStagesAnnotation.find(stage => stage.stageName == targetStage) &&
                                  toStagesAnnotation.find(stage => stage.stageName == targetStage).errorDict
                              "
                            >
                              软硬伤标记
                            </div>
                            <div
                              class="local-content-error-select"
                              v-if="
                                toStagesAnnotation.find(stage => stage.stageName == targetStage) &&
                                  toStagesAnnotation.find(stage => stage.stageName == targetStage).errorDict
                              "
                            >
                              <div style="display:flex;align-items: center;">
                                <div
                                  @click.stop="
                                    changeAll && !contentEdit
                                      ? selectLocalErrorDiff(
                                          0,
                                          diffItem,
                                          item,
                                          itemIndex,
                                          showOption.list.find(option => option.checked),
                                          diffIndex
                                        )
                                      : () => {}
                                  "
                                  :class="localInjuryType == 0 ? 'platte-error platte-error-select' : 'platte-error'"
                                  style="width: 28px;padding-left: 6px;"
                                >
                                  <span>无<a-icon v-if="!localInjuryType" type="check-circle" theme="filled"/></span>
                                </div>
                                <div
                                  @click.stop="
                                    changeAll && !contentEdit
                                      ? selectLocalErrorDiff(
                                          error.type,
                                          diffItem,
                                          item,
                                          itemIndex,
                                          showOption.list.find(option => option.checked),
                                          diffIndex,
                                          error.id
                                        )
                                      : () => {}
                                  "
                                  v-for="(error, errorIndex) in toStagesAnnotation.find(
                                    stage => stage.stageName == targetStage
                                  ).errorDict"
                                  :key="error.id"
                                  :class="
                                    localInjuryType == error.type ? 'platte-error platte-error-select' : 'platte-error'
                                  "
                                  :style="{
                                    color:
                                      error.dictName.indexOf('硬') > -1 || error.dictName.indexOf('错翻') > -1
                                        ? 'red'
                                        : 'orange',
                                    justifyContent: 'center',
                                    width: error.dictName.length == 1 ? '28px' : '40px',
                                    paddingLeft: !errorIndex ? '2px' : ''
                                  }"
                                >
                                  <span :style="{ width: error.dictName.length > 1 ? '40px' : '', textAlign: 'center' }"
                                    >{{ error.dictName
                                    }}<a-icon v-if="localInjuryType == error.type" type="check-circle" theme="filled"
                                  /></span>
                                </div>
                                <!--                                 <div
                                  @click.stop="
                                    changeAll && !contentEdit
                                      ? selectLocalErrorDiff(
                                          2,
                                          diffItem,
                                          item,
                                          itemIndex,
                                          showOption.list.find(option => option.checked),
                                          diffIndex
                                        )
                                      : () => {}
                                  "
                                  :class="localInjuryType == 2 ? 'platte-error platte-error-select' : 'platte-error'"
                                  style="color:red;width: 28px;padding-left: 6px;"
                                >
                                  <span
                                    >硬<a-icon v-if="localInjuryType == 2" type="check-circle" theme="filled"
                                  /></span>
                                </div>
                                <div
                                  v-if="targetStage != '监制' && taskDetail.stageName != '监制确认'"
                                  @click.stop="
                                    changeAll && !contentEdit
                                      ? selectLocalErrorDiff(
                                          1,
                                          diffItem,
                                          item,
                                          itemIndex,
                                          showOption.list.find(option => option.checked),
                                          diffIndex
                                        )
                                      : () => {}
                                  "
                                  :class="localInjuryType == 1 ? 'platte-error platte-error-select' : 'platte-error'"
                                  style="color:orange"
                                >
                                  <span
                                    >软<a-icon v-if="localInjuryType == 1" type="check-circle" theme="filled"
                                  /></span>
                                </div>
                                <div
                                  v-if="
                                    targetStage != '监制' && targetStage != '校对' && taskDetail.stageName != '监制确认'
                                  "
                                  @click.stop="
                                    changeAll && !contentEdit
                                      ? selectLocalErrorDiff(
                                          4,
                                          diffItem,
                                          item,
                                          itemIndex,
                                          showOption.list.find(option => option.checked),
                                          diffIndex
                                        )
                                      : () => {}
                                  "
                                  :class="localInjuryType == 4 ? 'platte-error platte-error-select' : 'platte-error'"
                                  style="color:red;"
                                >
                                  <span style="width:35px"
                                    >超硬<a-icon v-if="localInjuryType == 4" type="check-circle" theme="filled"
                                  /></span>
                                </div>
                                <div
                                  v-if="
                                    targetStage != '监制' && targetStage != '校对' && taskDetail.stageName != '监制确认'
                                  "
                                  @click.stop="
                                    changeAll && !contentEdit
                                      ? selectLocalErrorDiff(
                                          5,
                                          diffItem,
                                          item,
                                          itemIndex,
                                          showOption.list.find(option => option.checked),
                                          diffIndex
                                        )
                                      : () => {}
                                  "
                                  :class="localInjuryType == 5 ? 'platte-error platte-error-select' : 'platte-error'"
                                  style="color:orange;margin-left:8px"
                                >
                                  <span style="width:35px"
                                    >超软<a-icon v-if="localInjuryType == 5" type="check-circle" theme="filled"
                                  /></span>
                                </div> -->
                              </div>
                              <div
                                v-if="localInjuryType"
                                @mouseover.stop="isSelectedLocal = true"
                                @mouseleave.stop="isSelectedLocal = false"
                                style="flex:1"
                              >
                                <a-select
                                  style="width:100%;position:relative;top:3px"
                                  :dropdownStyle="{ zIndex: 1055 }"
                                  v-model="localInjuryReason"
                                  @mouseover.stop="isSelectedLocal = true"
                                  @mouseleave.stop="isSelectedLocal = false"
                                  @change="
                                    saveDiffLoacal(
                                      diffItem,
                                      item,
                                      itemIndex,
                                      showOption.list.find(option => option.checked),
                                      diffIndex
                                    )
                                  "
                                  :disabled="!changeAll"
                                  placeholder="请选择"
                                >
                                  <a-select-option
                                    @mouseover="isSelectedLocal = true"
                                    @mouseleave="isSelectedLocal = false"
                                    v-for="errorItem in dictList"
                                    :key="errorItem.id"
                                    :value="errorItem.itemText"
                                  >
                                    {{ errorItem.itemText }}
                                  </a-select-option>
                                </a-select>
                              </div>
                            </div>
                            <div
                              class="local-content-error-list"
                              v-if="
                                (diffItem.commentData && diffItem.commentData.length > 1) ||
                                  (diffItem.commentData &&
                                    diffItem.commentData.length == 1 &&
                                    diffItem.commentData[0].content)
                              "
                            >
                              <div
                                class="local-content-error-list-item"
                                v-for="(localItem, localItemIndex) in diffItem.commentData"
                                :key="localItem.id"
                                :style="{
                                  paddingBottom: localItemIndex != diffItem.commentData.length - 1 ? '5px' : ''
                                }"
                              >
                                <div class="local-content-error-list-item-top">
                                  <div class="local-content-error-list-item-top-left">
                                    <img :src="localItem.avatar" style="width: 20px;height: 20px;border-radius: 50%;" />
                                  </div>
                                  <div class="local-content-error-list-item-top-right">
                                    <div style="font-size:12px">{{ localItem.userName || '用户名' }}</div>
                                    <div style="font-size:12px">{{ localItem.createTimeStr }}</div>
                                  </div>
                                </div>
                                <div class="local-content-error-list-item-bottom">
                                  <div class="local-content-error-list-item-bottom-left"></div>
                                  <div class="local-content-error-list-item-bottom-right">
                                    <div
                                      class="show-local-content"
                                      @copy="textCopy"
                                      v-html="localItem.content || '无说明'"
                                      style="font-size:12px;color:black;min-height:18px;user-select: text;"
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="local-content-input"
                            @mouseover.stop="isSelectedLocal = true"
                            @mouseleave.stop="isSelectedLocal = false"
                          >
                            <j-editor
                              v-if="changeAll && !contentEdit"
                              ref="diffInput"
                              @mouseover.stop="isSelectedLocal = true"
                              @postValue="
                                saveDiffLoacal(
                                  diffItem,
                                  item,
                                  itemIndex,
                                  showOption.list.find(option => option.checked),
                                  diffIndex
                                )
                              "
                              :placeholder="'ALT+回车换行，回车提交'"
                              v-model="localValue"
                              :maxWidth="'50px'"
                              :toolbar="[]"
                            ></j-editor>
                          </div>
                        </div>
                      </div>
                    </template>
                  </template>
                  <template
                    v-if="
                      showOption.list.find(option => option.checked).title == '实时' &&
                        showOption.list.find(option => option.checked).num &&
                        item.textAreas &&
                        showTextArea == 'ON' &&
                        isShow
                    "
                  >
                    <vue-draggable-resizable
                      :resizable="false"
                      :draggable="selectTextAreaData.length == 1 && selectTextAreaId == textArea.id ? true : false"
                      :y="
                        textArea.translateTextAreaRegion.y * (showOption.list.find(option => option.checked).num || 1)
                      "
                      :x="
                        textArea.translateTextAreaRegion.x * (showOption.list.find(option => option.checked).num || 1)
                      "
                      :w="
                        textArea.translateTextAreaRegion && textArea.translateTextAreaRegion.width
                          ? textArea.translateTextAreaRegion.width *
                            (showOption.list.find(option => option.checked).num || 1)
                          : 0
                      "
                      :h="
                        textArea.translateTextAreaRegion.height *
                          (showOption.list.find(option => option.checked).num || 1)
                      "
                      :z="selectTextAreaData.some(t => t.id == textArea.id) ? 1 : 0"
                      @dragstop="
                        (left, top) =>
                          onDragstop(
                            left,
                            top,
                            textArea,
                            showOption.list.find(option => option.checked).num || 1,
                            showOption.list.find(option => option.checked)
                          )
                      "
                      v-for="textArea in item.textAreas"
                      :key="textArea.id"
                      style="border:none"
                    >
                      <div
                        :id="'textArea-' + textArea.id"
                        class="ocr-show-item-right"
                        @click.stop="selectTextArea(textArea, item, itemIndex)"
                        :style="{
                          overflowY: 'visible',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '100%',
                          height: '100%'
                        }"
                      >
                        <div
                          class="right-text-input"
                          v-if="showOption.list.find(option => option.checked)&&showOption.list.find(option => option.checked).num"
                          :id="item.id + '-' + textArea.id + '-' + 'editDiv'"
                          :style="{
                            cursor: 'pointer',
                            border: selectTextAreaId == textArea.id ? '4px dashed red' : '',
                            minHeight:
                              textArea.characterSentences.length == 1 &&
                              textArea.characterSentences[0].characterText == ''
                                ? textArea.characterSentences[0].fontSize + 30 + 'px'
                                : '',
                            minWidth:
                              textArea.characterSentences.length == 1 &&
                              textArea.characterSentences[0].characterText == ''
                                ? textArea.characterSentences[0].fontSize + 30 + 'px'
                                : '',
                            writingMode: textArea.textLayout == 2 ? 'vertical-rl' : 'horizontal-tb',
                            textAlign: textArea.characterSentences[0]
                              ? textArea.characterSentences[0].textAlign
                              : 'left',
                            lineHeight: textArea.characterSentences[0]
                              ? textArea.characterSentences[0].lineSpacing.toFixed(2)
                              : 1.15,
                            letterSpacing: '1px',
                            fontSize:
                              textArea.characterSentences.length == 1 &&
                              (textArea.characterSentences[0].characterText == '' ||
                                textArea.characterSentences[0].characterText == ' ')
                                ? textArea.characterSentences[0].fontSize + 'px'
                                : '',
                            color:
                              textArea.characterSentences.length == 1 &&
                              (textArea.characterSentences[0].characterText == '' ||
                                textArea.characterSentences[0].characterText == ' ')
                                ? textArea.characterSentences[0].fontColor
                                : '',
                            backgroundColor: textArea.backgroundColor || '#fff',
                            padding: getPadding(textArea),
                            position: 'relative',
                            left: getJustify(
                              textArea,
                              item,
                              showOption.list.find(option => option.checked)
                            )
                          }"
                        >
                          <p
                            v-for="p in textArea.showTexts"
                            :key="item.id + '-' + textArea.id + '-' + p.pId"
                            :id="item.id + '-' + textArea.id + '-' + p.pId"
                            style="margin:0;"
                          >
                            <span
                              v-for="(text, textIdx) in p.texts"
                              :key="text.characterSentenceId"
                              :id="item.id + '-' + textArea.id + '-' + text.characterSentenceId"
                              ><span
                                :class="
                                  text.hasMark && textArea.textLayout == 1
                                    ? 'stroke-span stroke-span-hasMark'
                                    : 'stroke-span'
                                "
                                :data-text="text.phonetic && text.phonetic.phoneticText"
                                :style="
                                  getTextStyle(text, textArea, showOption.list.find(option => option.checked).num)
                                "
                                :id="text.characterSentenceId"
                                ><span
                                  class="stroke-span-child"
                                  :id="text.characterSentenceId + '-' + 'text'"
                                  :data-text="text.strokeWidth && text.characterText != '' ? text.characterText : ''"
                                  :style="
                                    'white-space: pre-wrap;' +
                                      `transform:scale(${text.textWidth},${text.textHeight});` +
                                      `${
                                        textArea.textLayout == 2 && text.characterText == ' '
                                          ? `height:${text.fontSize / 3}px;`
                                          : ''
                                      }` +
                                      `${
                                        text.characterText == ' ' &&
                                        !textIdx &&
                                        !textArea.translateTextAreaRegion.editble
                                          ? 'display:inline-block;width:0;height:0;'
                                          : ''
                                      }` +
                                      `-webkit-text-stroke: ${text.strokeWidth * 2}px ${
                                        text.strokeColor
                                      };user-select:none;`
                                  "
                                  >{{ text.characterText != '' ? text.characterText : '' }}</span
                                >
                              </span>
                            </span>
                          </p>
                        </div>
                      </div>
                    </vue-draggable-resizable>
                  </template>
                  <template
                    v-if="
                      showOption.list.find(option => option.checked).title == '实时' &&
                        rightAnnotationList.length &&
                        rightAnnotationList[rightAnnotationList.findIndex(a => a.pageId == item.id)]
                    "
                  >
                    <div
                      v-for="(local, aIdx) in rightAnnotationList[
                        rightAnnotationList.findIndex(a => a.pageId == item.id)
                      ].annotationList"
                      :key="local.id + '-tanrlateLocal'"
                      :style="{
                        position: 'absolute',
                        top: local.position.y * (showOption.list.find(option => option.checked).num || 1) + 'px',
                        left: local.position.x * (showOption.list.find(option => option.checked).num || 1) + 'px'
                      }"
                      @click.stop="selectOldLocal(local)"
                      :id="local.id ? 'local-' + local.id : 'newLocal-' + aIdx"
                    >
                      <local-two
                        v-if="checkedToStages.some(toStage => toStage == local.toStage)"
                        @mouseover="isLocalDrag = true"
                        theme="filled"
                        :size="30"
                        :fill="local.selected ? color : 'orange'"
                        :strokeWidth="3"
                      />
                      <div
                        :style="
                          `transform:scale(${
                            showOption.list.find(option => option.checked) &&
                            showOption.list.find(option => option.checked).num
                              ? 1
                              : 1
                          });transform-origin: top left;`
                        "
                        class="local-content-old"
                        v-if="local.selected && checkedToStages.some(toStage => toStage == local.toStage)"
                      >
                        <div
                          class="local-content-list"
                          :style="{
                            padding: local.commentData && local.commentData.length ? '' : '0'
                          }"
                          v-if="local.commentData.length"
                        >
                          <div class="local-content-item" v-for="(i, cidx) in local.commentData" :key="cidx">
                            <div class="local-content-item-top">
                              <div class="local-content-item-top-left">
                                <img :src="i.avatar" alt="" />
                              </div>
                              <div class="local-content-item-top-right">
                                <div>
                                  {{ i.userName }}
                                </div>
                                <div>
                                  {{ i.createTimeStr }}
                                </div>
                              </div>
                            </div>
                            <div
                              class="local-content-item-bottom show-local-content"
                              @copy="textCopy"
                              v-html="i.content"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template
                    v-if="
                      showOption.list.find(option => option.checked).title == '原稿' &&
                        leftAnnotationList.length &&
                        leftAnnotationList[leftAnnotationList.findIndex(a => a.pageId == item.id)]
                    "
                  >
                    <div
                      v-for="(local, aIdx) in leftAnnotationList[leftAnnotationList.findIndex(a => a.pageId == item.id)]
                        .annotationList"
                      :key="local.id + '-tanrlateLocal'"
                      :style="{
                        position: 'absolute',
                        top: local.position.y * showOption.list.find(option => option.checked).num || 1 + 'px',
                        left: local.position.x * showOption.list.find(option => option.checked).num || 1 + 'px'
                      }"
                      @click.stop="selectOldLocal(local)"
                      :id="local.id ? 'local-' + local.id : 'newLocal-' + aIdx"
                    >
                      <local-two
                        @mouseover="isLocalDrag = true"
                        theme="filled"
                        :size="30"
                        :fill="local.selected ? color : 'orange'"
                        :strokeWidth="3"
                      />
                      <div
                        :style="
                          `transform:scale(${
                            showOption.list.find(option => option.checked) &&
                            showOption.list.find(option => option.checked).num
                              ? 1
                              : 1
                          });transform-origin: top left;`
                        "
                        class="local-content-old"
                        v-if="local.selected"
                      >
                        <div class="local-content-list" v-if="local.commentData.length">
                          <div class="local-content-item" v-for="(i, cidx) in local.commentData" :key="cidx">
                            <div class="local-content-item-top">
                              <div class="local-content-item-top-left">
                                <img :src="i.avatar" alt="" />
                              </div>
                              <div class="local-content-item-top-right">
                                <div>
                                  {{ i.userName }}
                                </div>
                                <div>
                                  {{ i.createTimeStr }}
                                </div>
                              </div>
                            </div>
                            <div class="local-content-item-bottom">{{ i.content }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <div
                    class="delete-border"
                    v-if="
                      selectTextAreaData.length > 1 &&
                        showOption.list.find(option => option.checked) &&
                        showOption.list.find(option => option.checked).title == '实时' &&
                        getDeleteBorder(showOption.list.find(option => option.checked)).idx === itemIndex
                    "
                    :style="getDeleteBorder(showOption.list.find(option => option.checked))"
                  ></div>
                </div>
              </div>
            </div>
            <div
              class="canvas-content"
              :id="'canvas-area-' + showOptionIndex"
              v-if="
                selectedPlatte &&
                  showOption.list.find(option => option.checked).isLatest &&
                  showOption.list.find(option => option.checked).title != '原稿' &&
                  showOption.list.find(option => option.checked).title != '实时'
              "
              :style="{
                top: showOption.list.find(option => option.checked)
                  ? showOption.list.find(option => option.checked).scrollTop + 'px'
                  : '',
                minHeight: '100%',
                width: showImgListWidth.length >= 4 ? showImgListWidth : '100%'
              }"
            >
              <v-stage
                :ref="'canvas-' + showOptionIndex + '-' + showOption.list.findIndex(option => option.checked)"
                :id="'canvas-' + showOptionIndex + '-' + showOption.list.findIndex(option => option.checked)"
                :config="stageSize"
                @mousedown="
                  e =>
                    handleMouseDown(
                      e,
                      showOption.list.find(option => option.checked),
                      showOptionIndex,
                      showOption
                    )
                "
                @mousemove="
                  e =>
                    handleMouseMove(
                      e,
                      showOption.list.find(option => option.checked)
                    )
                "
                @mouseup="
                  e =>
                    handleMouseUp(
                      e,
                      showOption.list.find(option => option.checked),
                      showOptionIndex,
                      showOption
                    )
                "
              >
                <v-layer>
                  <v-line
                    @transformend="transformend"
                    @transformstart="transformstart"
                    @mousedown="
                      e =>
                        handleDragStart(
                          e,
                          line,
                          showOption.list.find(option => option.checked).canvasObj,
                          line.platteType,
                          index,
                          showOptionIndex,
                          showOption
                        )
                    "
                    @mouseup="
                      e =>
                        handleDragEnd(
                          e,
                          line,
                          showOption.list.find(option => option.checked).canvasObj,
                          line.platteType,
                          showOptionIndex,
                          showOption
                        )
                    "
                    @mouseover="e => canvasMouseover(showOption.list.find(option => option.checked).canvasObj, e, line)"
                    @mouseleave="canvasMouseleave(showOption.list.find(option => option.checked).canvasObj)"
                    v-for="(line, index) in lines.filter(
                      line => line.type == showOption.list.find(option => option.checked).title
                    )"
                    :key="showOptionIndex + '-' + index + '-line'"
                    :config="line.data"
                  />
                  <v-rect
                    @transformend="transformend"
                    @transformstart="transformstart"
                    @mousedown="
                      e =>
                        handleDragStart(
                          e,
                          rect,
                          showOption.list.find(option => option.checked).canvasObj,
                          rect.platteType,
                          index,
                          showOptionIndex,
                          showOption
                        )
                    "
                    @mouseup="
                      e =>
                        handleDragEnd(
                          e,
                          rect,
                          showOption.list.find(option => option.checked).canvasObj,
                          rect.platteType,
                          showOptionIndex,
                          showOption
                        )
                    "
                    @mouseover="e => canvasMouseover(showOption.list.find(option => option.checked).canvasObj, e)"
                    @mouseleave="e => canvasMouseleave(showOption.list.find(option => option.checked).canvasObj)"
                    v-for="(rect, index) in rects.filter(
                      rect => rect.type == showOption.list.find(option => option.checked).title
                    )"
                    :key="showOptionIndex + '-' + index + '-rect'"
                    :config="rect.data"
                  />
                  <v-arrow
                    @transformend="transformend"
                    @transformstart="transformstart"
                    @mousedown="
                      e =>
                        handleDragStart(
                          e,
                          arrow,
                          showOption.list.find(option => option.checked).canvasObj,
                          arrow.platteType,
                          index,
                          showOptionIndex,
                          showOption
                        )
                    "
                    @mouseup="
                      e =>
                        handleDragEnd(
                          e,
                          arrow,
                          showOption.list.find(option => option.checked).canvasObj,
                          arrow.platteType,
                          showOptionIndex,
                          showOption
                        )
                    "
                    @mouseover="e => canvasMouseover(showOption.list.find(option => option.checked).canvasObj, e)"
                    @mouseleave="e => canvasMouseleave(showOption.list.find(option => option.checked).canvasObj)"
                    v-for="(arrow, index) in arrows.filter(
                      arrow => arrow.type == showOption.list.find(option => option.checked).title
                    )"
                    :key="showOptionIndex + '-' + index + '-arrow'"
                    :config="arrow.data"
                  />
                  <v-ellipse
                    @transformend="transformend"
                    @transformstart="transformstart"
                    @mousedown="
                      e =>
                        handleDragStart(
                          e,
                          ring,
                          showOption.list.find(option => option.checked).canvasObj,
                          ring.platteType,
                          index,
                          showOptionIndex,
                          showOption
                        )
                    "
                    @mouseup="
                      e =>
                        handleDragEnd(
                          e,
                          ring,
                          showOption.list.find(option => option.checked).canvasObj,
                          ring.platteType,
                          showOptionIndex,
                          showOption
                        )
                    "
                    @mouseover="e => canvasMouseover(showOption.list.find(option => option.checked).canvasObj, e)"
                    @mouseleave="e => canvasMouseleave(showOption.list.find(option => option.checked).canvasObj)"
                    v-for="(ring, index) in rings.filter(
                      ring => ring.type == showOption.list.find(option => option.checked).title
                    )"
                    :key="showOptionIndex + '-' + index + '-ring'"
                    :config="ring.data"
                  />
                  <v-text
                    @transformend="transformend"
                    @transformstart="transformstart"
                    @dblclick="e => textEdit(e, text, showOptionIndex, showOption, index)"
                    @mousedown="
                      e =>
                        handleDragStart(
                          e,
                          text,
                          showOption.list.find(option => option.checked).canvasObj,
                          text.platteType,
                          index,
                          showOptionIndex,
                          showOption
                        )
                    "
                    @mouseup="
                      e =>
                        handleDragEnd(
                          e,
                          text,
                          showOption.list.find(option => option.checked).canvasObj,
                          text.platteType,
                          showOptionIndex,
                          showOption
                        )
                    "
                    @mouseover="e => canvasMouseover(showOption.list.find(option => option.checked).canvasObj, e)"
                    @mouseleave="e => canvasMouseleave(showOption.list.find(option => option.checked).canvasObj)"
                    v-for="(text, index) in texts.filter(
                      text => text.type == showOption.list.find(option => option.checked).title
                    )"
                    :key="showOptionIndex + '-' + index + '-text'"
                    :config="text.data"
                  />
                  <v-transformer :enabledAnchors="[]" :padding="7" :rotateEnabled="false" />
                  <v-transformer ref="transformer" />
                </v-layer>
              </v-stage>
            </div>
            <div
              class="mask"
              v-show="
                is_show_mask &&
                  showOption.list.find(option => option.checked) &&
                  showOption.list.find(option => option.checked).title == '实时'
              "
              :style="'width:' + mask_width + 'left:' + mask_left + 'height:' + mask_height + 'top:' + mask_top"
            ></div>
          </div>
          <div
            v-if="showOptionIndex < showOptions.length - 1"
            @mousemove.stop="e => handleItemMouseMove(e, showOptionIndex)"
            @mousedown.stop="handleImgMouseDown(showOptionIndex)"
            :class="
              changeWidthShowOptionIndex == showOptionIndex
                ? 'show-img-content-item-change-width theme-color'
                : 'show-img-content-item-change-width'
            "
          ></div>
        </div>
        <div :class="isDragOption ? 'push-option push-option-color' : 'push-option'">
          <div @drop.stop="handleDrop" @dragenter.prevent="dragenter" @dragover.prevent @dragleave="dragleave">
            展开分栏
          </div>
        </div>
      </div>
      <a-menu slot="overlay">
        <a-menu-item @click="rightClick('black')" key="1"> 黑色 </a-menu-item>
        <a-menu-item @click="rightClick('#fff')" key="2"> 白色 </a-menu-item>
        <a-menu-item @click="rightClick('rgb(223,220,221)')" key="3"> 灰色 </a-menu-item>
      </a-menu>
    </a-dropdown>
    <div
      class="platte"
      :style="{
        width:
          toStagesAnnotation.find(stage => stage.stageName == targetStage) &&
          toStagesAnnotation.find(stage => stage.stageName == targetStage).errorDict
            ? toStagesAnnotation.find(s => s.stageName == targetStage).isShow
              ? toStagesAnnotation.find(stage => stage.stageName == targetStage).errorDict.length == 4
                ? '610px'
                : toStagesAnnotation.find(stage => stage.stageName == targetStage).errorDict.length == 3
                ? '550px'
                : toStagesAnnotation.find(stage => stage.stageName == targetStage).errorDict.length == 2
                ? '520px'
                : '490px'
              : '520px'
            : '396px'
      }"
      v-if="selectedPlatte"
    >
      <!--       <div class="platte-select" v-if="false">
        <a-select
          style="width:100%;"
          size="small"
          :dropdownStyle="{ zIndex: 1055 }"
          v-model="platteInjuryInjuryReason"
          placeholder="请选择"
        >
          <a-select-option v-for="errorItem in dictList" :key="errorItem.id" :value="errorItem.itemText">
            {{ errorItem.itemText }}
          </a-select-option>
        </a-select>
      </div> -->
      <div
        class="platte-select"
        v-if="toStagesAnnotation.length"
        :style="{
          paddingLeft: '14px',
          width:
            toStagesAnnotation.length == 4
              ? '380px'
              : toStagesAnnotation.length == 5
              ? '420px'
              : toStagesAnnotation.length == 6
              ? '460px'
              : ''
        }"
      >
        <span style="font-size:16px;">
          To:
        </span>
        <div style="margin-left:10px;">
          <a-radio-group v-model="targetStage" @change="toStageChange(null)">
            <a-radio
              :value="stage.stageName"
              :disabled="!changeAll || isAutoSave || contentEdit"
              v-for="stage in toStagesAnnotation.filter(s => {
                return (changeAll && !contentEdit) || s.stageName == targetStage
              })"
              :key="stage.stageName"
            >
              {{ stage.stageName }}
            </a-radio>
          </a-radio-group>
        </div>
      </div>
      <div class="platte-content" @mouseup="platteMouseup" @mousedown="platteMouseDown">
        <div class="platte-content-top" @mouseup="platteMouseup">
          <div style="display:flex">
            <div
              class="platte-option"
              @click.stop="selectPlatte('rect')"
              @mouseover="color1 = color"
              @mouseout="color1 = '#4a4a4a'"
            >
              <square theme="outline" size="21" :fill="platteType == 'rect' ? color : color1" :strokeWidth="3" />
            </div>
            <div
              class="platte-option"
              @click.stop="selectPlatte('ring')"
              @mouseover="color2 = color"
              @mouseout="color2 = '#4a4a4a'"
            >
              <round theme="outline" size="21" :fill="platteType == 'ring' ? color : color2" :strokeWidth="3" />
            </div>
            <div class="platte-option" @click.stop="selectPlatte('line')">
              <span
                class="iconfont icon-line-fill icon"
                :style="{ fontSize: '24px', color: platteType == 'line' ? color : '' }"
              ></span>
            </div>
            <div
              class="platte-option"
              @click.stop="selectPlatte('arrow')"
              @mouseover="color3 = color"
              @mouseout="color3 = '#4a4a4a'"
            >
              <arrow-right-up
                theme="outline"
                size="24"
                :fill="platteType == 'arrow' ? color : color3"
                :strokeWidth="3"
              />
            </div>
            <div
              class="platte-option"
              @click.stop="selectPlatte('pencil')"
              @mouseover="color4 = color"
              @mouseout="color4 = '#4a4a4a'"
            >
              <pencil theme="outline" size="20" :fill="platteType == 'pencil' ? color : color4" :strokeWidth="3" />
            </div>
            <div class="platte-option" @click.stop="selectPlatte('text')">
              <span :style="{ fontSize: '21px', fontWeight: 500, color: platteType == 'text' ? color : '' }">A</span>
            </div>
          </div>
          <div
            :style="`display:flex;align-items: center;`"
            v-if="
              toStagesAnnotation.find(stage => stage.stageName == targetStage) &&
                toStagesAnnotation.find(stage => stage.stageName == targetStage).errorDict
            "
          >
            <div
              @click="selectPlatteError(0)"
              :class="!platteInjuryType ? 'platte-error platte-error-select' : 'platte-error'"
              style="border-left: 1px solid rgba(0,0,0,0.25);justify-content:center"
            >
              <span>无<a-icon v-if="!platteInjuryType" type="check-circle" theme="filled"/></span>
            </div>
            <div
              v-for="error in toStagesAnnotation.find(stage => stage.stageName == targetStage).errorDict"
              :key="error.id"
              @click="selectPlatteError(error.type, error.id)"
              :class="platteInjuryType == error.type ? 'platte-error platte-error-select' : 'platte-error'"
              :style="{
                color: error.dictName.indexOf('硬') > -1 || error.dictName.indexOf('错翻') > -1 ? 'red' : 'orange',
                justifyContent: 'center',
                width: error.dictName.length > 1 ? '50px' : '',
                paddingLeft: error.dictName.length > 1 ? '5px' : '',
                paddingRight: error.dictName.length > 1 ? '5px' : ''
              }"
            >
              <span :style="{ width: error.dictName.length > 1 ? '38px' : '' }"
                >{{ error.dictName }}<a-icon v-if="platteInjuryType == error.type" type="check-circle" theme="filled"
              /></span>
            </div>
            <!--             <div
              v-if="targetStage != '监制' && taskDetail.stageName != '监制确认'"
              @click="changeAll && !contentEdit ? selectPlatteError(1) : () => {}"
              :class="platteInjuryType == 1 ? 'platte-error platte-error-select' : 'platte-error'"
              style="color:orange"
            >
              <span>软<a-icon v-if="platteInjuryType == 1" type="check-circle" theme="filled"/></span>
            </div>
            <div
              v-if="targetStage != '监制' && targetStage != '校对' && taskDetail.stageName != '监制确认'"
              @click.stop="changeAll && !contentEdit ? selectPlatteError(4) : () => {}"
              :class="platteInjuryType == 4 ? 'platte-error platte-error-select' : 'platte-error'"
              style="color:red;width:40px;"
            >
              <span style="width:38px"
                >超硬<a-icon v-if="platteInjuryType == 4" type="check-circle" theme="filled"
              /></span>
            </div>
            <div
              v-if="targetStage != '监制' && targetStage != '校对' && taskDetail.stageName != '监制确认'"
              @click.stop="changeAll && !contentEdit ? selectPlatteError(5) : () => {}"
              :class="platteInjuryType == 5 ? 'platte-error platte-error-select' : 'platte-error'"
              style="color:orange;margin-left:8px;width:40px;margin-right:10px;"
            >
              <span style="width:38px"
                >超软<a-icon v-if="platteInjuryType == 5" type="check-circle" theme="filled"
              /></span>
            </div> -->
          </div>
          <div
            style="display:flex;flex:1;padding-top:12px;padding-bottom:11px;"
            @mousemove="noDrag = true"
            @mouseout="noDrag = false"
          >
            <div
              class="platte-action"
              style="border-left: 1px solid rgba(0,0,0,0.25);"
              @click.stop="undo"
              @mouseover="color5 = color"
              @mouseout="color5 = '#4a4a4a'"
            >
              <undo theme="outline" size="20" :fill="color5" :strokeWidth="3" />
            </div>
            <div class="platte-action" @click.stop="closePlatte"><a-icon style="font-size:18px;" type="close" /></div>
            <div class="platte-action" @click.stop="savePlatte">
              <a-icon v-if="!platteLoading" style="font-size:18px;" type="check" />
              <a-spin v-else>
                <a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
              </a-spin>
            </div>
          </div>
        </div>
        <div class="platte-content-bottom" @mousedown.stop @mouseup="platteMouseup">
          <j-editor
            ref="platteInput"
            @postValue="savePlatte"
            :isWindows="isWindows"
            @redo="redo"
            @undo="undo"
            v-model="platteValue"
            :disabled="platteLoading"
            :maxWidth="'50px'"
            :toolbar="[]"
          ></j-editor>
        </div>
      </div>
      <div class="platte-top" @mouseup="platteMouseup" v-if="platteType && !platteLoading">
        <div>
          <div class="platte-top-left">
            <div>
              <div class="platte-top-label">大小</div>
              <div class="platte-top-slider" style="padding-left:10px;">
                <a-slider
                  v-model="sliderValue"
                  @change="changeSlider"
                  :defaultValue="3"
                  :max="maxSliderValue"
                  :min="minSliderValue"
                />
              </div>
            </div>
          </div>
          <div class="platte-top-right">
            <div>
              <div
                :class="
                  platteColor == 'red'
                    ? 'platte-top-color-item platte-top-color-item-selected'
                    : 'platte-top-color-item'
                "
                @click="changePlatteColor('red')"
                :style="{ backgroundColor: 'red' }"
              ></div>
              <div
                :class="
                  platteColor == 'yellow'
                    ? 'platte-top-color-item platte-top-color-item-selected'
                    : 'platte-top-color-item'
                "
                @click="changePlatteColor('yellow')"
                :style="{ backgroundColor: 'yellow' }"
              ></div>
              <div
                :class="
                  platteColor == 'green'
                    ? 'platte-top-color-item platte-top-color-item-selected'
                    : 'platte-top-color-item'
                "
                @click="changePlatteColor('green')"
                :style="{ backgroundColor: 'green' }"
              ></div>
              <div
                :class="
                  platteColor == 'blue'
                    ? 'platte-top-color-item platte-top-color-item-selected'
                    : 'platte-top-color-item'
                "
                @click="changePlatteColor('blue')"
                :style="{ backgroundColor: 'blue' }"
              ></div>
              <div
                :class="
                  platteColor == '#fff'
                    ? 'platte-top-color-item platte-top-color-item-selected'
                    : 'platte-top-color-item'
                "
                @click="changePlatteColor('#fff')"
                :style="{ backgroundColor: '#fff', border: '1px solid #4a4a4a' }"
              ></div>
              <div
                :class="
                  platteColor == '#999999'
                    ? 'platte-top-color-item platte-top-color-item-selected'
                    : 'platte-top-color-item'
                "
                @click="changePlatteColor('#999999')"
                :style="{ backgroundColor: '#999999' }"
              ></div>
              <div
                :class="
                  platteColor == '#000'
                    ? 'platte-top-color-item platte-top-color-item-selected'
                    : 'platte-top-color-item'
                "
                @click="changePlatteColor('#000')"
                :style="{ backgroundColor: '#000' }"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="diff-model" v-if="selectedDiff">
      <div class="diff-model-title">
        图像对比
      </div>
      <div class="diff-model-body">
        <div>
          <div>
            <div style="width:40%">对比容差：</div>
            <div><a-input-number :min="0" v-model="diffData.pixelToleranceLevel" /></div>
          </div>
          <div style="margin-top:5px">
            <div style="width:40%">参照稿：</div>
            <div class="diff-scroll">
              <a-radio-group v-model="diffData.leftTitle">
                <a-radio
                  v-for="option in allOption.filter(
                    option =>
                      option.title != '原稿' &&
                      option.title != '原稿JPG' &&
                      option.title != '实时' &&
                      (option.title.indexOf(diffFilterString) > -1 ||
                        (diffFilterString == '上色' && option.title.indexOf('后期') > -1) ||
                        (diffFilterString == '后期' && option.title.indexOf('上色') > -1))
                  )"
                  :key="option.id"
                  :style="radioStyle"
                  :value="option.title"
                >
                  {{ option.title }}
                </a-radio>
              </a-radio-group>
            </div>
          </div>
          <div>
            <div style="width:40%">对齐方式：</div>
            <div>
              <a-radio-group v-model="diffData.flag">
                <a-radio :style="radioStyle" :value="1">
                  顶部对齐
                </a-radio>
                <a-radio :style="radioStyle" :value="2">
                  底部对齐
                </a-radio>
              </a-radio-group>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div style="width:40%">标记大小：</div>
            <div><a-input-number :min="0" v-model="diffData.threshold" /></div>
          </div>
          <div style="margin-top:5px">
            <div style="width:40%">对比稿：</div>
            <div class="diff-scroll">
              <a-radio-group v-model="diffData.rightTitle">
                <a-radio
                  v-for="option in allOption.filter(
                    option =>
                      option.title != '原稿' &&
                      option.title != '原稿JPG' &&
                      option.title != '实时' &&
                      (option.title.indexOf(diffFilterString) > -1 ||
                        (diffFilterString == '上色' && option.title.indexOf('后期') > -1) ||
                        (diffFilterString == '后期' && option.title.indexOf('上色') > -1))
                  )"
                  :key="option.id"
                  :style="radioStyle"
                  :value="option.title"
                >
                  {{ option.title }}
                </a-radio>
              </a-radio-group>
            </div>
          </div>
        </div>
      </div>
      <div class="diff-model-footer">
        <a-button size="small" style="margin-right:12px;" @click="closeDiffModal">取消</a-button>
        <a-button size="small" type="primary" @click="diff">对比</a-button>
      </div>
    </div>
    <errorModal
      ref="errorModal"
      @clearSelectErrorType="clearSelectErrorType"
      @changeIsSelectedLocal="changeIsSelectedLocal"
      @errorChangePlatte="errorChangePlatte"
      @errorChange="errorChange"
      :dictList="dictList"
    />
    <div :contenteditable="true" id="platteEditDiv"></div>
  </div>
</template>
<script>
import { Square, Round, ArrowRightUp, Pencil, Undo, LocalTwo, ClearFormat } from '@icon-park/vue'
import { simpleDebounce } from '@/utils/util'
import { postAction, getAction, deleteAction } from '../../../api/manage'
import { checkPermission } from './../utils/hasPermission'
import errorModal from './errorModal'
import JEditor from './JEditor2'
export default {
  components: {
    Square,
    Round,
    ArrowRightUp,
    Pencil,
    Undo,
    JEditor,
    LocalTwo,
    errorModal
  },
  props: {
    color: {
      type: String,
      default: '#000000'
    },
    showImgListWidth: {
      type: String,
      default: '100%'
    },
    departName: {
      type: String,
      default: ''
    },
    selectedDiffIdx: {
      type: String,
      default: ''
    },
    selectLocalId: {
      type: [String, Number],
      default: ''
    },
    diffTitle: {
      type: String,
      default: ''
    },
    showTextArea: {
      type: String,
      default: 'ON'
    },
    showDiffs: {
      type: String,
      default: 'ON'
    },
    diffFilterString: {
      type: String,
      default: ''
    },
    showOptions: {
      type: Array,
      default: () => []
    },
    rightAnnotationList: {
      type: Array,
      default: () => []
    },
    leftAnnotationList: {
      type: Array,
      default: () => []
    },
    showOptions: {
      type: Array,
      default: () => []
    },
    stageSize: {
      type: Object,
      default: () => ({})
    },
    taskDetail: {
      type: Object,
      default: () => ({})
    },
    userInfo: {
      type: Object,
      default: () => ({})
    },
    errorDictList: {
      type: Array,
      default: () => []
    },
    checkedStages: {
      type: Array,
      default: () => []
    },
    checkedToStages: {
      type: Array,
      default: () => ['制作环节']
    },
    showImgLeftListPostions: {
      type: Array,
      default: () => []
    },
    allOption: {
      type: Array,
      default: () => []
    },
    toStagesAnnotation: {
      type: Array,
      default: () => []
    },
    toStagesLeaveMessage: {
      type: Array,
      default: () => []
    },
    diffs: {
      type: Array,
      default: () => []
    },
    authList: {
      type: Array,
      default: () => []
    },
    selectTextAreaData: {
      type: Array,
      default: () => []
    },
    selectIdx: {
      type: Number,
      default: 0
    },
    changeScrollHeihgt: {
      type: Number,
      default: 0
    },
    radioValue: {
      type: Number,
      default: 0
    },
    selectedPlatte: {
      type: Boolean,
      default: false
    },
    isLocalZhongShen: {
      type: Boolean,
      default: false
    },
    isAutoSave: {
      type: Boolean,
      default: false
    },
    isOpenVideo: {
      type: Boolean,
      default: false
    },
    isDownLoad: {
      type: Boolean,
      default: false
    },
    isWindows: {
      type: Boolean,
      default: false
    },
    selectedDiff: {
      type: Boolean,
      default: false
    },
    changeAll: {
      type: Boolean,
      default: false
    },
    isAddAnnotation: {
      type: Boolean,
      default: false
    },
    isToDiff: {
      type: Boolean,
      default: false
    },
    contentEdit: {
      type: Boolean,
      default: false
    },
    isChangePageList: {
      type: Boolean,
      default: false
    },
    hasLocal: {
      type: Boolean,
      default: false
    },
    scrollSync: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isStartTrans: false,
      platteLoading: false,
      draggableShow: false,
      diffData: {
        pixelToleranceLevel: 15,
        threshold: 75,
        flag: 1,
        leftTitle: '',
        rightTitle: ''
      },
      start_x: 0,
      start_y: 0,
      end_x: 0,
      end_y: 0,
      select_list: [],
      is_show_mask: true,
      box_screen_left: 0,
      box_screen_top: 0,
      lines: [],
      targetStage: '',
      rects: [],
      arrows: [],
      rings: [],
      texts: [],
      backgroundColor: 'rgb(223,220,221)',
      minSliderValue: 3,
      maxSliderValue: 50,
      sliderValue: 3,
      isDragOption: false,
      isDrag: false,
      platteDrag: false,
      noDrag: false,
      dragOptionValue: '',
      localValue: '',
      clientX: 0,
      color1: '#4a4a4a',
      color2: '#4a4a4a',
      color3: '#4a4a4a',
      color4: '#4a4a4a',
      color5: '#4a4a4a',
      platteType: 'rect',
      canvasObj: null,
      selectErrorData: null,
      canvasIndex: -1,
      undoList: [
        {
          lines: [],
          rects: [],
          arrows: [],
          rings: [],
          texts: []
        }
      ],
      redoList: [],
      isAddText: false,
      isShow: false,
      platteColor: 'red',
      platteStyle: {
        rect: {
          size: 3,
          color: 'red'
        },
        ring: {
          size: 3,
          color: 'red'
        },
        arrow: {
          size: 3,
          color: 'red'
        },
        pencil: {
          size: 3,
          color: 'red'
        },
        line: {
          size: 3,
          color: 'red'
        },
        text: {
          size: 8,
          color: 'red'
        }
      },
      isChangeImgwidth: false,
      isChangeItemWidth: false,
      loadImgList: [],
      checkSyncTabs: [],
      dictList: [],
      maxImgHeight: 0,
      needChangeScroll: true,
      isSelectedLocal: false,
      platteValue: '',
      platteInjuryType: 0,
      localInjuryType: 0,
      oldLocalInjuryType: 0,
      selectedCanvas: null,
      selectData: null,
      drawdown: false,
      isChangePage: false,
      dragStartX: 0,
      dragStartY: 0,
      changeWidthShowOptionIndex: -1,
      platteInjuryInjuryReason: undefined,
      localInjuryReason: undefined,
      oldPlatteInjuryType: 0,
      oldPlatteInjuryInjuryReason: undefined,
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px'
      },
      penList: [],
      isDragging: false,
      selectTextAreaId: null
    }
  },
  watch: {
    platteValue(newval) {
      this.platteValue = newval
      if (this.$refs.platteInput) {
        this.$refs.platteInput.EditorOnsize()
      }
    },
    localValue(newval) {
      this.localValue = newval
      if (this.$refs.localInput && this.$refs.localInput[0]) {
        this.$refs.localInput[0].EditorOnsize()
      }
    }
  },
  computed: {
    getTextStyle() {
      return (text, item, num) => {
        let pNums = []
        let brIndexs = [0, ...item.brIndexs]
        let pIndexs = [...item.pIndexs]
        let textIdx = -1

        if (this.departName.indexOf('日语部') > -1) {
          textIdx = item.characterSentences.findIndex(t => t.characterSentenceId == text.characterSentenceId)
          /*           item.characterSentences.forEach((t, index) => {
            if (t.characterText == '<br>') brIndexs.push(index)
            if (
              item.characterSentences[index + 1] &&
              item.characterSentences[index + 1].phonetic &&
              item.characterSentences[index + 1].phonetic.phoneticText
            ) {
              pIndexs.push(index)
            }
          }) */
          if (
            item.characterSentences[0] &&
            item.characterSentences[0].phonetic &&
            item.characterSentences[0].phonetic.phoneticText
          ) {
            pIndexs.unshift(0)
          }
          pIndexs.forEach(pIndex => {
            brIndexs.forEach((brIndex, idx) => {
              if (
                brIndexs[idx + 1] &&
                pIndex >= brIndex &&
                pIndex < brIndexs[idx + 1] &&
                pNums.every(p => p.starIdx != brIndex && p.endIdx != brIndexs[idx + 1])
              ) {
                pNums.push({ starIdx: brIndex, endIdx: brIndexs[idx + 1] })
              }
              if (
                !brIndexs[idx + 1] &&
                pIndex >= brIndex &&
                pNums.every(p => p.starIdx != brIndex && p.endIdx != textIdx)
              ) {
                pNums.push({ starIdx: brIndex, endIdx: textIdx })
              }
            })
          })
        }
        let pNum = 0
        pNums.forEach(p => {
          if (textIdx >= p.starIdx) pNum++
        })
        let lineSpacing = text.lineSpacing || 1.15
        let pTotal = pNum > 1 ? 5 : 0
        let textMove =
          ((pNum * ((text.fontSize - 1) / 2 + 1) * lineSpacing).toFixed(0) - pTotal) * num + (num > 1.7 ? 10 : 0) + 'px;'
        if (text.phonetic) {
          textMove = ((pNum * text.phonetic.fontSize * lineSpacing).toFixed(0) - pTotal) * num + (num > 1.7 ? 10 : 0) + 'px;'
        } else {
          if (
            item.characterSentences.findIndex(
              t => t.phonetic && t.phonetic.targetCharacterIds?.some(targetId => targetId == text.characterSentenceId)
            ) > -1
          ) {
            let fs = item.characterSentences.find(
              t => t.phonetic && t.phonetic.targetCharacterIds?.some(targetId => targetId == text.characterSentenceId)
            ).phonetic.fontSize
            textMove = ((pNum * fs * lineSpacing).toFixed(0) - 5) * num + (num > 1.7 ? 10 : 0) + 'px;'
          }
        }
        if (item.textLayout == 2) {
          textMove = 'left:-' + textMove
        } else {
          textMove = 'top:' + textMove
        }
        textMove = textMove.replaceAll('--', '-')
        let style =
          `font-size:${(text.fontSize * num).toFixed(0) - 0}px;` +
          `color:${text.fontColor};` +
          `font-family:${text.fontFamily ? 'fontCssView' + text.fontFamily : ''};` +
          textMove +
          `font-weight:${text.isBold ? 'bold' : ''};` +
          `font-style:${text.isIncline ? 'italic' : ''};` +
          `transform:scale(${(text.strokeWidth * 2 * num).toFixed(0) - 0}px,${text.textHeight}px);`

        if (text.phonetic && text.phonetic.phoneticText) {
          let top = 0
          let left = 0
          if (item.textLayout == 2) {
            let changeLeft = (text.fontSize - text.phonetic.fontSize) * num
            if (!changeLeft) changeLeft = 6
              left = `${changeLeft+2}px;`
          } else {
            let changeTop = ((text.fontSize * num - text.phonetic.fontSize * num) / 3).toFixed(0) - 0
            top =
              '-' +
              (
                (pNum ? 1 : 0) * text.fontSize * num * text.lineSpacing -
                (text.fontSize * num - text.phonetic.fontSize * num) / 2
              ).toFixed(0) -
              0 +
              changeTop +
              'px;'
          }
          let lineHeightW = 'calc(100% - 2px);'
          let lineHeightH = 'calc(100% - 2px);'
          if (item.textLayout == 2) {
            lineHeightH = `${text.phonetic.phoneticText.length * text.phonetic.fontSize * num}px;`
            if (text.phonetic.targetCharacterIds) {
              top =
                (
                  (text.fontSize * num * text.phonetic.targetCharacterIds.length -
                    text.phonetic.phoneticText.length * text.phonetic.fontSize * num) /
                  2
                ).toFixed(0) + 'px;'
            }
          } else {
            lineHeightW = `${text.phonetic.phoneticText.length * text.phonetic.fontSize * num}px;`
            /*           if (text.phonetic.lineSpacing && text.phonetic.lineSpacing * 100 - 80 > 0) {
            top = `-${(text.phonetic.lineSpacing * 100 - 80).toFixed(0)}%;`
          } else {
            top = `${(80 - text.phonetic.lineSpacing * 100).toFixed(0)}%;`
          } */
            if (text.phonetic.targetCharacterIds) {
              left =
                (
                  (text.fontSize * num * text.phonetic.targetCharacterIds.length -
                    text.phonetic.phoneticText.length * text.phonetic.fontSize * num) /
                  2
                ).toFixed(0) -
                (2 / num).toFixed(0) +
                'px;'
            }
          }
          let pStyle =
            `--pFontSize: ${(text.phonetic.fontSize * num).toFixed(0) - 0}px;` +
            `--pLineHeightW:${lineHeightW}` +
            `--pLineHeightH:${lineHeightH}` +
            `--transform:scale(${text.phonetic.scaleX || 1},${text.phonetic.scaleY || 1});` +
            `--letterSpacing:-${text.phonetic.letterSpacing}px;` +
            `--bold:${text.isBold ? 'bold;' : ''};` +
            `--isIncline:${text.isIncline ? 'italic;' : ''};` +
            `--stroke:${(text.strokeWidth * 2 * num).toFixed(0) - 0}px ${text.strokeColor};` +
            `--pBack:${text.strokeColor};` +
            `--pTop:${top}` +
            `--pLeft:${left}`
          style = style + pStyle
        }
        if (text.hasMark) {
          var reg = /^[a-zA-Z0-9]+$/
          let left = (text.fontSize - (text.fontSize / 8) * num) / 2
          if (reg.test(text.characterText)) {
            if (
              text.characterText == 'f' ||
              text.characterText == 'i' ||
              text.characterText == 'j' ||
              text.characterText == 'l' ||
              text.characterText == 't'
            ) {
              left = left / 2
            }
            if (text.characterText == '1') {
              left = left * 1.5
            }
            left = left / 2
          }
          left = (left * num).toFixed(0) + 'px;'
          let bottom = ((text.fontSize / 20) * num).toFixed(0) + 'px;'
          let mStyle =
            `--mFontSize: ${(((text.fontSize - 1) / 8) * num).toFixed(0) - 0}px;` +
            `--mBack:${text.fontColor};` +
            `--mLeft:${left}` +
            `--mBorderRadius:${text.fontFamily == 'FZHei-B01S' ? 'none' : '50%'};` +
            `--mBottom:-${bottom}`
          style = style + mStyle
        }
        return style
      }
    },
    mask_width() {
      return `${Math.abs(this.end_x - this.start_x)}px;`
    },
    mask_top() {
      return `${Math.min(this.start_y, this.end_y) - this.box_screen_top}px;`
    },
    mask_left() {
      return `${Math.min(this.start_x, this.end_x) - this.box_screen_left}px;`
    },
    mask_height() {
      return `${Math.abs(this.end_y - this.start_y)}px;`
    }
  },
  methods: {
    textCopy(e) {
      const text = e.target.parentNode.innerText
      const textareaEle = document.createElement('textarea')
      document.body.appendChild(textareaEle)
      // 2. 将需要复制的文本传入输入框, 并调用 select 方法, 选中输入框中文本
      textareaEle.value = text.replaceAll('\n\n', '\n')
      textareaEle.select()
      textareaEle.readOnly = 'readOnly'
      // 3. 调用复制选中文本的方法
      document.execCommand('copy')
      // 4. 销毁输入框
      document.body.removeChild(textareaEle)
    },
    getDeleteBorder(option) {
      let style = {
        idx: null
      }
      if (this.showImgLeftListPostions.length && option.manuscriptList.length) {
        let num = option.num || 1
        let arr1 = JSON.parse(JSON.stringify(this.selectTextAreaData))
        let arr2 = JSON.parse(JSON.stringify(this.selectTextAreaData))
        let arrMax1 = JSON.parse(JSON.stringify(arr1)).map(s => {
          return {
            ...s,
            x: s.translateTextAreaRegion.x - 0 + s.translateTextAreaRegion.width
          }
        })
        let arrMax2 = JSON.parse(JSON.stringify(arr2)).map(s => {
          return {
            ...s,
            y: s.translateTextAreaRegion.y - 0 + s.translateTextAreaRegion.height
          }
        })
        let arrMaxX = arrMax1.sort((a, b) => a.x - b.x)
        let arrMaxY = arrMax2.sort((a, b) => a.y - b.y)
        let arrX = arr1.sort((a, b) => a.translateTextAreaRegion.x - b.translateTextAreaRegion.x)
        let arrY = arr2.sort((a, b) => a.translateTextAreaRegion.y - b.translateTextAreaRegion.y)
        style = {
          top: arrY[0].translateTextAreaRegion.y * num + 'px',
          left: arrX[0].translateTextAreaRegion.x * num + 'px',
          width: arrMaxX[arrMax1.length - 1].x * num - 0 - arrX[0].translateTextAreaRegion.x * num + 'px',
          height: arrMaxY[arrMax2.length - 1].y * num - 0 - arrY[0].translateTextAreaRegion.y * num + 'px',
          idx: option.manuscriptList.findIndex(item => item.id == arrY[0].pageId)
        }
      }
      return style
    },
    getJustify(item, page, option) {
      let left = '0'
      const dom = document.getElementById(page.id + '-' + item.id + '-' + 'editDiv')
      if (dom) {
        let num = option.num || 1
        let x = item.translateTextAreaRegion.x * num
        let width = item.textAreaRegion.width * num
        let domWidth = dom.getClientRects()[0].width
        if (domWidth - width > 0 && (dom.clientWidth - width) / 2 > x) {
          left = (domWidth - width) / 2 - x + 'px !important'
        }
        if (domWidth - width > 0 && x + width + (domWidth - width) / 2 > page.imgWidth) {
          left = page.imgWidth - (x + width + (domWidth - width) / 2) - 5 + 'px !important'
        }
      }
      return left
    },
    selectTextArea(textArea, page, pageIdx) {
      this.selectTextAreaId = textArea.id
      this.$emit('selectTextArea', page, textArea, pageIdx)
    },
    toStageChange(local, item, itemIndex, option, annotationKind) {
      this.localInjuryType = 0
      this.localInjuryReason = ''
      this.platteInjuryType = 0
      this.platteInjuryInjuryReason = ''
      this.dictList = []
      if (local) {
        local.injuryType = 0
        local.injuryReason = ''
        if (local.commentData && local.commentData.length) {
          this.saveLoacal(local, item, itemIndex, option, annotationKind, true)
        }
      }
    },
    toStageChangeDiff(diffItem, item, itemIndex, option, diffIndex) {
      this.localInjuryType = 0
      this.localInjuryReason = ''
      this.platteInjuryType = 0
      this.platteInjuryInjuryReason = ''
      this.dictList = []
      /* if (this.localValue) {
        this.$emit('saveDiffLoacal', diffItem, item, itemIndex, option, diffIndex)
      } */
    },
    clearSelectErrorType() {
      this.selectErrorData = null
      this.platteInjuryType = this.oldPlatteInjuryType
      this.platteInjuryInjuryReason = this.oldPlatteInjuryInjuryReason
      this.localInjuryType = this.oldLocalInjuryType
    },
    errorChangePlatte(value) {
      this.platteInjuryInjuryReason = value
    },
    showImgMousedown(e, showOption) {
      if (showOption.list.find(option => option.checked).title != '实时') return
      if (
        e.target.className &&
        typeof e.target.className == 'string' &&
        e.target.className.indexOf('show-page-content') > -1
      ) {
        this.is_show_mask = true
        let showOptionIndex = this.showOptions.findIndex(showOption =>
          showOption.list.find(option => option.title == '实时')
        )
        const dom = document.getElementById('show-img-bottom-' + showOptionIndex)
        this.start_x = e.layerX
        this.start_y = e.clientY - dom.getClientRects()[0].y + dom.scrollTop
        this.end_x = e.layerX
        this.end_y = e.clientY - dom.getClientRects()[0].y + dom.scrollTop
        document.body.addEventListener('mousemove', this.handleImgMouseMove)
        document.body.addEventListener('mouseup', this.handleImgMouseUp)
      }
    },
    handleImgMouseMove(event) {
      if (this.isAddAnnotation) {
        this.start_x = 0
        this.start_y = 0
        this.end_x = 0
        this.end_y = 0
        return
      }
      let showOptionIndex = this.showOptions.findIndex(showOption =>
        showOption.list.find(option => option.title == '实时')
      )
      const dom = document.getElementById('show-img-bottom-' + showOptionIndex)
      this.end_x = event.clientX - dom.getClientRects()[0].x
      this.end_y = event.clientY - dom.getClientRects()[0].y + dom.scrollTop
    },
    handleImgMouseUp() {
      setTimeout(() => {
        document.body.removeEventListener('mousemove', this.handleImgMouseMove)
        document.body.removeEventListener('mouseup', this.handleImgMouseUp)
        this.is_show_mask = false
        this.handleDomSelect()
        this.resSetXY()
      }, 0)
    },
    handleDomSelect() {
      if (this.end_x - this.start_x == 0 && this.start_y - this.end_y == 0) return
      const dom_mask = window.document.querySelector('.mask')
      const rect_select = dom_mask.getClientRects()[0]
      const add_list = []
      const del_list = []
      let doms = document.querySelectorAll('.ocr-show-item-right')
      doms.forEach(node => {
        if (!node.getClientRects()[0]) return
        const rects = node.getClientRects()[0]
        if (this.collide(rects, rect_select) === true) {
          let arr = []
          arr.push({ id: node.id })
          if (
            this.select_list?.includes(
              arr?.find(item => {
                return item.id == node.id
              }).id
            )
          ) {
            del_list.push(
              arr.find(item => {
                return item.id == node.id
              }).id
            )
          } else {
            add_list.push(
              arr.find(item => {
                return item.id == node.id
              }).id
            )
          }
        }
      })
      this.$emit('clearSelectTextAreaData')
      this.select_list = this.select_list.concat(add_list).filter(item => !del_list.includes(item))
      let showOption = this.showOptions.find(showOption => showOption.list.find(option => option.title == '实时'))
      if (showOption) {
        let option = showOption.list.find(option => option.title == '实时')
        option.manuscriptList?.forEach(page => {
          page.textAreas?.forEach(i => {
            if (this.select_list.some(item => item.split('-')[1] == i.id)) {
              this.selectTextAreaId = i.id
              this.$emit('pushSelectTextAreaData', i)
            } else {
            }
          })
        })
      }
    },
    collide(rect1, rect2) {
      if (!rect1 || !rect2) return
      const maxX = Math.max(rect1.x + rect1.width, rect2.x + rect2.width)
      const maxY = Math.max(rect1.y + rect1.height, rect2.y + rect2.height)
      const minX = Math.min(rect1.x, rect2.x)
      const minY = Math.min(rect1.y, rect2.y)
      if (maxX - minX <= rect1.width + rect2.width && maxY - minY <= rect1.height + rect2.height) {
        return true
      } else {
        return false
      }
    },
    resSetXY() {
      this.start_x = 0
      this.start_y = 0
      this.end_x = 0
      this.end_y = 0
    },
    errorChange(value) {
      if (this.selectErrorData && this.selectErrorData.local) {
        this.localInjuryReason = value
        this.selectErrorData.local.injuryType = this.localInjuryType
        this.saveError(
          this.selectErrorData.local,
          this.selectErrorData.item,
          this.selectErrorData.itemIndex,
          this.selectErrorData.option,
          this.selectErrorData.annotationKind
        )
      } else if (this.selectErrorData && this.selectErrorData.diffItem) {
        this.localInjuryReason = value
        this.saveDiffError(
          this.selectErrorData.diffItem,
          this.selectErrorData.item,
          this.selectErrorData.itemIndex,
          this.selectErrorData.option,
          this.selectErrorData.diffIndex
        )
      }
    },
    getMaxShowContentStyle(option, showOptionIndex) {
      let minHeight = '100%'
      let width = this.showImgListWidth
      let position = 'relative'
      let left = 0
      let arr = []
      let manuscriptList = option.manuscriptList || []
      manuscriptList.forEach(item => {
        if (
          item.annotations &&
          item.annotations.filter(local => local.annotationKind != 1 && local.annotationKind != 3).length
        ) {
          item.annotations
            .filter(local => local.annotationKind != 1 && local.annotationKind != 3)
            .forEach(local => {
              arr.push(local)
            })
        }
      })
      if (arr.length) {
        arr.sort((a, b) => {
          a.position.width - b.position.width
        })
        let local = arr[arr.length - 1]

        /* if (local.position.x < 0) {
          left = 0 - local.position.x * option.num + 'px'
        } */
      }
      return { minHeight, width, position, left }
    },
    EditorOnsize() {
      setTimeout(() => {
        if (this.$refs.localInput && this.$refs.localInput[0]) {
          this.$refs.localInput[0].EditorOnsize()
        }
      }, 300)
    },
    dragging(x, y, local) {
      this.isSelectedLocal = true
      /*       const dom = document.getElementById('ocr-show-page-text-' + local.id)
      this.$nextTick(() => {
        if (x < 0 && y) {
          dom.style.transform = `translate(0px, ${y}px) !important`
        } else if (y < 0 && x) {
          dom.style.transform = `translate(${x}px, 0px) !important`
        } else if (x < 0 && y < 0) {
          dom.style.transform = `translate(0px, 0px) !important`
        }
        console.log(x, y, dom.style.transform, 123)
      }) */
    },
    changeIsSelectedLocal(isSelectedLocal) {
      this.isSelectedLocal = isSelectedLocal
    },
    checkPermission(permission) {
      return checkPermission(permission, this.authList)
    },
    selectOldLocal(local) {
      this.rightAnnotationList.forEach(r => {
        r.annotationList?.forEach(local => {
          local.selected = 0
        })
      })
      this.leftAnnotationList.forEach(r => {
        r.annotationList?.forEach(local => {
          local.selected = 0
        })
      })
      local.selected = 1
      this.$emit('showImgHandleImgClick')
      this.$forceUpdate()
    },
    getPadding(item) {
      let style = '8px 8px 8px 8px'
      let brIndexs = [0]
      let pIndexs = []
      if (item.characterSentences.every(t => !t.phonetic)) return style
      if (item.characterSentences.length && item.characterSentences[0].fontSize) {
        item.characterSentences.forEach((t, index) => {
          if (t.characterText == '<br>') brIndexs.push(index)
          if (
            item.characterSentences[index + 1] &&
            item.characterSentences[index + 1].phonetic &&
            item.characterSentences[index + 1].phonetic.phoneticText &&
            pIndexs.every(pIdx => pIdx != brIndexs[brIndexs.length - 1])
          ) {
            pIndexs.push(brIndexs[brIndexs.length - 1])
          }
        })
        let num = (item.characterSentences[0].fontSize / 2) * (pIndexs.length - 0 + 1) + 8
        if (item.textLayout == 1) {
          style = '8px 8px ' + num + 'px 8px'
        } else {
          style = '8px 8px 8px ' + num + 'px'
        }
      }
      return style
    },
    handleMouseWheel(event, showOptionIndex) {
      if (this.selectedPlatte) return
      const delta = event.deltaY || event.wheelDelta
      const direction = delta > 0 && this.isWindows ? 1 : -1
      this.$refs['show-img-top-' + showOptionIndex][0].scrollLeft += direction * 50
    },
    diff() {
      if (!this.diffData.leftTitle || !this.diffData.rightTitle) {
        this.$message.error('参照稿与对比稿不能为空')
        return
      }
      if (!this.diffData.leftTitle == this.diffData.rightTitle) {
        this.$message.error('参照稿与对比稿不能相同')
        return
      }
      this.$emit('diff')
    },
    closeDiffModal() {
      this.$emit('closeDiffModal')
    },
    async selectPlatteError(type, id) {
      if (this.platteLoading) return
      if (this.platteInjuryType == type) {
        this.platteInjuryType = 0
        this.platteInjuryInjuryReason = undefined
      } else {
        this.oldPlatteInjuryType = this.platteInjuryType
        this.oldPlatteInjuryInjuryReason = this.platteInjuryInjuryReason
        this.platteInjuryType = type
        /*         let code = !this.platteInjuryType
          ? ''
          : this.platteInjuryType == 1
          ? 'hy-cgrs'
          : this.platteInjuryType == 2
          ? 'hy-cgys'
          : this.platteInjuryType == 4
          ? 'hy-cgcjsy'
          : this.platteInjuryType == 5
          ? 'hy-cgcjrs'
          : ''
        if (this.departName.indexOf('日语部') > -1) {
          if (this.targetStage != '校对') {
            code = !this.platteInjuryType
              ? ''
              : this.platteInjuryType == 1
              ? 'ry-cgrs'
              : this.platteInjuryType == 2
              ? 'ry-cgys'
              : this.platteInjuryType == 4
              ? 'ry-cgcjys'
              : this.platteInjuryType == 5
              ? 'ry-cgcjrs'
              : ''
          } else {
            code = !this.platteInjuryType
              ? ''
              : this.platteInjuryType == 1
              ? 'ry-rs'
              : this.platteInjuryType == 2
              ? 'ry-ys'
              : ''
          }
          if (this.targetStage == '监制') {
            code = 'ry-cgxt-zs'
          }
        } else {
          if (this.targetStage == '校对') {
            code = !this.platteInjuryType
              ? ''
              : this.platteInjuryType == 1
              ? 'hy-rs'
              : this.platteInjuryType == 2
              ? 'hy-ys'
              : ''
          }
        } */
        if (id) {
          /* let id = this.errorDictList.find(item => item.dictCode == code).id */
          const res = await getAction('/sys/dictItem/list', { pageNo: 1, pageSize: 50, dictId: id })
          if (res.code == 200) {
            this.dictList = res.data.records
            if (!this.dictList.length) return this.$message.error('无错误类型数据')
            this.$refs.errorModal.open(true)
          }
        } else {
          this.platteInjuryInjuryReason = undefined
        }
      }
    },
    async selectLocalError(type, local, item, itemIndex, option, annotationKind) {
      this.selectErrorData = {
        local,
        item,
        itemIndex,
        option,
        annotationKind: annotationKind || 1
      }
      this.oldLocalInjuryType = this.localInjuryType
      if (this.localInjuryType == type) {
        this.localInjuryType = type
        let code = !this.localInjuryType
          ? ''
          : this.localInjuryType == 1
          ? 'hy-cgrs'
          : this.localInjuryType == 2
          ? 'hy-cgys'
          : this.localInjuryType == 4
          ? 'hy-cgcjsy'
          : this.localInjuryType == 5
          ? 'hy-cgcjrs'
          : ''
        if (this.departName.indexOf('日语部') > -1) {
          if (local.targetStage != '校对') {
            code = !this.localInjuryType
              ? ''
              : this.localInjuryType == 1
              ? 'ry-cgrs'
              : this.localInjuryType == 2
              ? 'ry-cgys'
              : this.localInjuryType == 4
              ? 'ry-cgcjys'
              : this.localInjuryType == 5
              ? 'ry-cgcjrs'
              : ''
          } else {
            code = !this.localInjuryType
              ? ''
              : this.localInjuryType == 1
              ? 'ry-rs'
              : this.localInjuryType == 2
              ? 'ry-ys'
              : ''
          }
          if (local.targetStage == '监制') {
            code = 'ry-cgxt-zs'
          }
        } else {
          if (local.targetStage == '校对') {
            code = !this.localInjuryType
              ? ''
              : this.localInjuryType == 1
              ? 'hy-rs'
              : this.localInjuryType == 2
              ? 'hy-ys'
              : ''
          }
        }
        if (this.errorDictList.find(item => item.dictCode == code)) {
          let id = this.errorDictList.find(item => item.dictCode == code).id
          const res = await getAction('/sys/dictItem/list', { pageNo: 1, pageSize: 50, dictId: id })
          if (res.code == 200) {
            this.dictList = res.data.records
            if (!this.dictList.length) return this.$message.error('无错误类型数据')
            this.$refs.errorModal.edit(this.localInjuryReason || '')
          } else {
            this.$message.error('无错误类型数据')
          }
        }
      } else {
        this.localInjuryType = type
        this.localInjuryReason = undefined
        let code = !this.localInjuryType
          ? ''
          : this.localInjuryType == 1
          ? 'hy-cgrs'
          : this.localInjuryType == 2
          ? 'hy-cgys'
          : this.localInjuryType == 4
          ? 'hy-cgcjsy'
          : this.localInjuryType == 5
          ? 'hy-cgcjrs'
          : ''
        if (this.departName.indexOf('日语部') > -1) {
          if (local.targetStage != '校对') {
            code = !this.localInjuryType
              ? ''
              : this.localInjuryType == 1
              ? 'ry-cgrs'
              : this.localInjuryType == 2
              ? 'ry-cgys'
              : this.localInjuryType == 4
              ? 'ry-cgcjys'
              : this.localInjuryType == 5
              ? 'ry-cgcjrs'
              : ''
          } else {
            code = !this.localInjuryType
              ? ''
              : this.localInjuryType == 1
              ? 'ry-rs'
              : this.localInjuryType == 2
              ? 'ry-ys'
              : ''
          }
          if (local.targetStage == '监制') {
            code = 'ry-cgxt-zs'
          }
        } else {
          if (local.targetStage == '校对') {
            code = !this.localInjuryType
              ? ''
              : this.localInjuryType == 1
              ? 'hy-rs'
              : this.localInjuryType == 2
              ? 'hy-ys'
              : ''
          }
        }
        if (this.errorDictList.find(item => item.dictCode == code)) {
          let id = this.errorDictList.find(item => item.dictCode == code).id
          const res = await getAction('/sys/dictItem/list', { pageNo: 1, pageSize: 50, dictId: id })
          if (res.code == 200) {
            this.dictList = res.data.records
            if (!this.dictList.length) return this.$message.error('无错误类型数据')
            this.$refs.errorModal.open(false)
          }
        } else {
          this.$message.error('无错误类型数据')
        }
      }
    },
    async selectLocalErrorDiff(type, diffItem, item, itemIndex, option, diffIndex, id) {
      this.selectErrorData = {
        diffItem,
        item,
        itemIndex,
        option,
        diffIndex
      }
      this.oldLocalInjuryType = this.localInjuryType
      if (this.localInjuryType == type) {
        this.localInjuryType = type
        /*         let code = !this.localInjuryType
          ? ''
          : this.localInjuryType == 1
          ? 'hy-cgrs'
          : this.localInjuryType == 2
          ? 'hy-cgys'
          : this.localInjuryType == 4
          ? 'hy-cgcjsy'
          : this.localInjuryType == 5
          ? 'hy-cgcjrs'
          : ''
        if (this.departName.indexOf('日语部') > -1) {
          if (this.targetStage != '校对') {
            code = !this.localInjuryType
              ? ''
              : this.localInjuryType == 1
              ? 'ry-cgrs'
              : this.localInjuryType == 2
              ? 'ry-cgys'
              : this.localInjuryType == 4
              ? 'ry-cgcjys'
              : this.localInjuryType == 5
              ? 'ry-cgcjrs'
              : ''
          } else {
            code = !this.localInjuryType
              ? ''
              : this.localInjuryType == 1
              ? 'ry-rs'
              : this.localInjuryType == 2
              ? 'ry-ys'
              : ''
          }
          if (this.targetStage == '监制') {
            code = 'ry-cgxt-zs'
          }
        } else {
          if (this.targetStage == '校对') {
            code = !this.localInjuryType
              ? ''
              : this.localInjuryType == 1
              ? 'hy-rs'
              : this.localInjuryType == 2
              ? 'hy-ys'
              : ''
          }
        } */
        if (id) {
          const res = await getAction('/sys/dictItem/list', { pageNo: 1, pageSize: 50, dictId: id })
          if (res.code == 200) {
            this.dictList = res.data.records
            if (!this.dictList.length) return this.$message.error('无错误类型数据')
            this.$refs.errorModal.edit(this.localInjuryReason || '')
          }
        }
      } else {
        this.localInjuryType = type
        this.localInjuryReason = undefined
        let code = !this.localInjuryType
          ? ''
          : this.localInjuryType == 1
          ? 'hy-cgrs'
          : this.localInjuryType == 2
          ? 'hy-cgys'
          : this.localInjuryType == 4
          ? 'hy-cgcjsy'
          : this.localInjuryType == 5
          ? 'hy-cgcjrs'
          : ''
        if (this.departName.indexOf('日语部') > -1) {
          if (this.targetStage != '校对') {
            code = !this.localInjuryType
              ? ''
              : this.localInjuryType == 1
              ? 'ry-cgrs'
              : this.localInjuryType == 2
              ? 'ry-cgys'
              : this.localInjuryType == 4
              ? 'ry-cgcjys'
              : this.localInjuryType == 5
              ? 'ry-cgcjrs'
              : ''
          } else {
            code = !this.localInjuryType
              ? ''
              : this.localInjuryType == 1
              ? 'ry-rs'
              : this.localInjuryType == 2
              ? 'ry-ys'
              : ''
          }
          if (this.targetStage == '监制') {
            code = 'ry-cgxt-zs'
          }
        } else {
          if (this.targetStage == '校对') {
            code = !this.localInjuryType
              ? ''
              : this.localInjuryType == 1
              ? 'hy-rs'
              : this.localInjuryType == 2
              ? 'hy-ys'
              : ''
          }
        }
        if (this.errorDictList.find(item => item.dictCode == code)) {
          let id = this.errorDictList.find(item => item.dictCode == code).id
          const res = await getAction('/sys/dictItem/list', { pageNo: 1, pageSize: 50, dictId: id })
          if (res.code == 200) {
            this.dictList = res.data.records
            if (!this.dictList.length) return this.$message.error('无错误类型数据')
            this.$refs.errorModal.open(false)
          }
        }
      }
    },
    async selectLocalErrorTwo(type, injuryReason, local) {
      this.localInjuryType = type
      this.localInjuryReason = injuryReason
      let code = !this.localInjuryType
        ? ''
        : this.localInjuryType == 1
        ? 'hy-cgrs'
        : this.localInjuryType == 2
        ? 'hy-cgys'
        : this.localInjuryType == 4
        ? 'hy-cgcjsy'
        : this.localInjuryType == 5
        ? 'hy-cgcjrs'
        : ''
      if (this.departName.indexOf('日语部') > -1) {
        if (local && local.targetStage != '校对') {
          code = !this.localInjuryType
            ? ''
            : this.localInjuryType == 1
            ? 'ry-cgrs'
            : this.localInjuryType == 2
            ? 'ry-cgys'
            : this.localInjuryType == 4
            ? 'ry-cgcjys'
            : this.localInjuryType == 5
            ? 'ry-cgcjrs'
            : ''
        } else {
          code = !this.localInjuryType
            ? ''
            : this.localInjuryType == 1
            ? 'ry-rs'
            : this.localInjuryType == 2
            ? 'ry-ys'
            : ''
        }
        if (local && local.targetStage == '监制') {
          code = 'ry-cgxt-zs'
        }
      } else {
        if (local && local.targetStage == '校对') {
          code = !this.localInjuryType
            ? ''
            : this.localInjuryType == 1
            ? 'hy-rs'
            : this.localInjuryType == 2
            ? 'hy-ys'
            : ''
        }
      }
      if (this.errorDictList.find(item => item.dictCode == code)) {
        let id = this.errorDictList.find(item => item.dictCode == code).id
        const res = await getAction('/sys/dictItem/list', { pageNo: 1, pageSize: 50, dictId: id })
        if (res.code == 200) {
          this.dictList = res.data.records
          if (!this.dictList.length) return this.$message.error('无错误类型数据')
        }
      }
    },
    saveLoacal(local, item, itemIndex, option, annotationKind, isSaveValue) {
      this.$emit('saveLoacal', local, item, itemIndex, option, annotationKind, isSaveValue)
    },
    saveError(local, item, itemIndex, option, annotationKind) {
      this.$emit('saveError', local, item, itemIndex, option, annotationKind)
    },
    saveDiffLoacal(diffItem, item, itemIndex, option, diffIndex) {
      this.$emit('saveDiffLoacal', diffItem, item, itemIndex, option, diffIndex)
    },
    saveDiffError(diffItem, item, itemIndex, option, diffIndex) {
      this.$emit('saveDiffLoacal', diffItem, item, itemIndex, option, diffIndex)
    },
    selectDiff(i, index) {
      this.$emit('selectDiff', { index, i })
      this.$nextTick(() => {
        if (this.$refs.diffInput && this.$refs.diffInput[0]) {
          this.$refs.diffInput[0].EditorOnsize()
        }
      })
    },
    deleteLocal(local, page, idx, annotations) {
      const dom = document.getElementById('ocr-show-page-text-' + local.id)
      let imgs = null
      if (Array.from(dom.getElementsByTagName('IMG')) && Array.from(dom.getElementsByTagName('IMG')).length) {
        imgs = Array.from(dom.getElementsByTagName('IMG')).map(e => e.src)
      }
      if ((local.id + '').indexOf('newLocal') > -1) {
        this.$confirm({
          content: `确认删除？`,
          zIndex: 9999,
          onOk: () => {
            let deleIdex = annotations.findIndex(l => l.id == local.id)
            annotations.splice(deleIdex, 1)
            this.$emit('closeLocal', imgs)
            this.$forceUpdate()
          }
        })
        return
      }
      this.$confirm({
        content: `确认删除？`,
        zIndex: 9999,
        onOk: () => {
          this.$emit('deleError', local, page)
          this.$emit('closeLocal', imgs)
        }
      })
    },
    inputFocus() {},
    inputBlur() {},
    getActionCloseStyle(num) {
      /*       if (!num) num = 1
      let style = `transform:scale(${num.toFixed(2)});transform-origin: bottom left;` */
      return ''
    },
    getLocalActionStyle(showPostion, option, type, item) {
      let left = ''
      let topStyle = ''
      let num = option.num || 1
      let domWidth = this.taskDetail.departName.indexOf('原创部') > -1 ? 270 : 330
      if (type) {
        let top = (item.position.y * num).toFixed(0) - 0
        let height = (32 * num).toFixed(0) - 0 || 1
        /* let domWidth =(330 * num).toFixed(0) - 0 */
        let domX = (item.position.x * num).toFixed(0) - 0 + (showPostion.imgX - 0)
        let domMaxX = domX + domWidth
        let domMinX = domX
        let fatherMaxX = showPostion.imgWidth - 0 + (showPostion.imgX - 0)
        let fatherMinx = showPostion.imgX - 0
        let outWidth = 0
        let dir = 'left'
        if (domMinX < fatherMinx) {
          outWidth = fatherMinx - domMinX
          dir = 'left'
        } else {
          outWidth = domMaxX - fatherMaxX
          dir = 'right'
        }
        if (top + height > showPostion.imgHeight - 250) {
          topStyle = 'bottom: calc(100% + 26px);'
        } else {
          topStyle = 'top:calc(100% + 12px)'
        }
        if (outWidth > 0) {
          if (dir == 'left') {
            left = 'left:' + outWidth + 'px;'
          } else {
            left = 'left:-' + outWidth + 'px;'
          }
        } else {
          left = 'left:0px;'
        }
      }
      let style =
        /* `transform:scale(${num.toFixed(2)});transform-origin: top left;` + */ 'width:' +
        domWidth +
        'px !important;' +
        left +
        topStyle
      return style
    },
    getAnnotationStyle(item, option, type) {
      let style = {}
      if (option.num) {
        let num = option.num || 1
        num = num.toFixed(2) - 0
        let size = item.size || 30
        let width =
          size /* * num */
            .toFixed(0) - 0
        let height =
          size /* * num */
            .toFixed(0) - 0
        let topNum = 0
        if (num > 1) {
          topNum = -8
        }
        let leftNum = (8 * num).toFixed(0)
        let top = item.position.y - 0 - size / 2 - topNum
        top = (top * num).toFixed(0) - 0
        let left = (item.position.x * num).toFixed(0) - 0 - leftNum
        style = {
          w: width || 30,
          h: height || 30,
          x: left,
          y: top,
          z: 97
        }
      }
      return style
    },
    onActivatedAnnotation(local, option) {
      /* if (local.selected) return */
      option.manuscriptList.forEach(item => {
        item.annotations?.forEach(l => {
          l.selected = 0
        })
      })
      local.selected = 1
      this.localValue = ''
      if (!local.injuryReason) {
        local.injuryType = 0
      }
      this.localInjuryType = local.injuryType
      this.localInjuryReason = local.injuryReason || undefined
      this.isActive = true
      this.$emit('changeSelectLocalId', local.id)
      this.$emit('clearSelectedDiffIdxTwo')
      this.$forceUpdate()
      setTimeout(() => {
        if (this.$refs.localInput && this.$refs.localInput[0]) {
          this.$refs.localInput[0].EditorOnsize()
        }
      }, 500)
    },
    onDeactivatedAnnotation(local) {
      /*       if (this.isSelectedLocal || this.isOpenVideo) return
      local.selected = 0
      this.$forceUpdate()
      this.localValue = ''
      this.localInjuryReason = undefined
      this.localInjuryType = 0 */
    },
    onDragstop(left, top, item, num) {
      item.translateTextAreaRegion.x = (left / num).toFixed(0) - 0
      item.translateTextAreaRegion.y = (top / num).toFixed(0) - 0
    },
    onDragstopAnnotation(left, top, item, option, i, idx) {
      let num = option.num || 1
      item.position.x = (left / num).toFixed(0) - 0 + ((7 / num).toFixed(0) - 0)
      let size = item.size || 30
      size = size * num
      let yNum = top - 0 + size / 2
      item.position.y = (yNum / num).toFixed(0) - 0 + ((4 / num).toFixed(0) - 0)
      if ((item.id + '').indexOf('newLocal') > -1 && item.commentData && !item.commentData.content) {
        this.isSelectedLocal = false
      } else {
        this.$emit('saveLoacal', item, i, idx, option)
        this.isSelectedLocal = false
      }
    },
    showClick(event, option, itemIndex) {
      this.selectTextAreaId = null
      this.$emit('clearSelectTextAreaData')
      this.select_list = []
      if (this.isAddAnnotation) {
        if (option.isLatest && option.title != '实时' && option.title != '原稿') {
          let y = event.offsetY
          let x = event.offsetX
          if (event.target.tagName == 'IMG') {
            y = y + event.target.offsetTop
          }
          let position = {
            x: (x / option.num).toFixed(0),
            y: (y / option.num).toFixed(0)
          }
          this.$emit('changeAnnotationList', { position, idx: itemIndex, option })
          const timeOut = setTimeout(() => {
            if (this.$refs.localInput && this.$refs.localInput[0]) {
              this.$refs.localInput[0].EditorOnsize()
            }
            clearTimeout(timeOut)
          }, 200)
        }
        if (this.isSelectedLocal) return
        this.localValue = ''
        this.localInjuryReason = undefined
        this.localInjuryType = 0
        this.rightAnnotationList.forEach(r => {
          r.annotationList?.forEach(local => {
            local.selected = 0
          })
        })
        this.leftAnnotationList.forEach(r => {
          r.annotationList?.forEach(local => {
            local.selected = 0
          })
        })
        this.$forceUpdate()
      } else {
        if (this.isSelectedLocal) return
        this.localValue = ''
        this.localInjuryReason = undefined
        this.localInjuryType = 0
        this.selectTextAreaId = null
        this.$emit('clearSelectedDiffIdx')
        this.rightAnnotationList.forEach(r => {
          r.annotationList?.forEach(local => {
            local.selected = 0
          })
        })
        this.leftAnnotationList.forEach(r => {
          r.annotationList?.forEach(local => {
            local.selected = 0
          })
        })
        this.showOptions.forEach(showOption => {
          showOption.list?.forEach(option => {
            option.manuscriptList?.forEach(item => {
              item.annotations?.forEach(local => {
                local.selected = 0
              })
            })
          })
        })
        this.$forceUpdate()
      }
    },
    changePage(optionTitle) {
      if (this.scrollSync) {
        this.isChangePage = true
        this.showOptions.forEach((showOption, showOptionIndex) => {
          if (
            showOption.list &&
            showOption.list.find(option => option.checked) &&
            this.checkSyncTabs.some(title => title == showOption.list.find(option => option.checked).title)
          ) {
            console.log({ dom: document.getElementById('show-img-bottom-' + showOptionIndex) })
            document.getElementById('show-img-bottom-' + showOptionIndex).scrollTop =
              this.showImgLeftListPostions.find(item => item.idx == this.selectIdx).top - 153 + this.changeScrollHeihgt
            showOption.list.find(option => option.checked).scrollTop = document.getElementById(
              'show-img-bottom-' + showOptionIndex
            ).scrollTop
          }
        })
        const timeOut = setTimeout(() => {
          this.isChangePage = false
          clearTimeout(timeOut)
        }, 50)
      } else {
        let showOptionIndex = this.showOptions.findIndex(showOption =>
          showOption.list?.some(option => option.title == optionTitle)
        )
        if (showOptionIndex == -1) showOptionIndex = 0
        document.getElementById('show-img-bottom-' + showOptionIndex).scrollTop =
          this.showImgLeftListPostions.find(item => item.idx == this.selectIdx).top - 153 + this.changeScrollHeihgt
        this.showOptions.forEach(showOption => {
          showOption.list.find(option => option.checked).scrollTop = document.getElementById(
            'show-img-bottom-' + showOptionIndex
          ).scrollTop
        })
      }
    },
    loadImg() {
      console.log('开始加载')
      let loadImgs = []
      this.loadImgList = []
      this.isChangePage = true
      this.showOptions.forEach(showOption => {
        this.loadImgList.push(showOption.list.find(item => item.checked))
      })
      this.loadImgList.sort((a, b) => b.manuscriptList.length - a.manuscriptList.length)
      this.loadImgList[0].manuscriptList.forEach(item => {
        let that = this
        if (!that.$refs[item.id + 'showLeftImg']) return
        var img = that.$refs[item.id + 'showLeftImg'][0]
        img.onload = function() {
          loadImgs.push(img)
          if (loadImgs.length == that.showOptions[0].list.find(item => item.checked).manuscriptList.length) {
            that.allUpload()
          }
        }
      })
    },
    allUpload() {
      this.maxImgHeight = 0
      if (this.needChangeScroll) {
        this.showOptions.forEach((showOption, showOptionIndex) => {
          let dom = document.getElementById('show-img-bottom-' + showOptionIndex)
          if (dom && showOption.list.find(option => option.checked)) {
            dom.scrollTop = showOption.list.find(option => option.checked).scrollTop
            let checkedOptionIndex = showOption.list.findIndex(option => option.checked)
            this.$refs['show-img-top-' + showOptionIndex][0].scrollLeft =
              (checkedOptionIndex + 1) * 60 - this.$refs['show-img-top-' + showOptionIndex][0].clientWidth + 30
          }
        })
        this.needChangeScroll = false
      }
      const timeOut = setTimeout(() => {
        this.$emit('getShowImgListPostions')
        this.isShow = true
        clearTimeout(timeOut)
      }, 500)
    },
    handleRightMouseDown() {
      this.isChangeImgwidth = true
    },
    handleImgMouseDown(showOptionIndex) {
      this.isChangeItemWidth = true
      this.changeWidthShowOptionIndex = showOptionIndex
    },
    handleItemMouseMove(e, showOptionIndex) {
      /*       if (this.isChangeItemWidth) {
        this.showOptions.forEach((s, idx) => {
          if (idx == showOptionIndex) {
            if (!s.changeWidth) s.changeWidth = 0
            s.changeWidth = s.changeWidth - e.movementX
          } else {
            if (!s.changeWidth) s.changeWidth = 0
            s.changeWidth = s.changeWidth + e.movementX / (this.showOptions.length - 1)
          }
        })
      } */
    },
    changePlatteColor(color) {
      this.platteColor = color
      if (this.platteType) this.platteStyle[this.platteType].color = color
      this.showOptions.forEach((showOption, showOptionIndex) => {
        showOption.list.forEach((option, optionIndex) => {
          if (option.title == '原稿' || option.title == '原稿JPG' || option.title == '实时') return
          if (option.checked && option.canvasObj) {
            option.canvasObj.drawcolor = this.platteColor
          }
        })
      })
      if (this.selectData) {
        if (this.selectData.type != 'text') {
          this.selectData.data.data.stroke = color
          if (this.selectData.type == 'arrow') {
            this.selectData.data.data.fill = color
          }
        } else {
          this.selectData.data.data.fill = color
        }
      }
    },
    changeSlider(value) {
      let num = 1
      this.showOptions.forEach(showOption => {
        if (showOption.list.find(option => option.checked)) {
          num = showOption.list.find(option => option.checked).num
        }
      })
      if (this.selectData) {
        if (this.selectData.type == 'arrow') {
          this.selectData.data.data.pointerLength = value * 4 * num
          this.selectData.data.data.pointerWidth = value * 4 * num
          this.selectData.data.data.strokeWidth = value * num
        } else if (this.selectData.type == 'text') {
          this.selectData.data.data.fontSize = (value * 4 + 1) * num
        } else {
          this.selectData.data.data.strokeWidth = value * num
        }
      }
      if (this.platteType) this.platteStyle[this.platteType].size = value
    },
    undo() {
      if (this.platteLoading) return
      this.selectData = null
      if (this.undoList.length === 1) return
      const reLastPath = this.undoList.pop()
      const lastPath = this.undoList[this.undoList.length - 1]
      this.redoList.push(JSON.parse(JSON.stringify(reLastPath)))
      this.lines = JSON.parse(JSON.stringify(lastPath.lines))
      this.rects = JSON.parse(JSON.stringify(lastPath.rects))
      this.arrows = JSON.parse(JSON.stringify(lastPath.arrows))
      this.rings = JSON.parse(JSON.stringify(lastPath.rings))
      this.texts = JSON.parse(JSON.stringify(lastPath.texts))
      this.showOptions.forEach(showOption => {
        showOption.list?.forEach(option => {
          if (option.isLatest) {
            option.canvasObj.stage
              .getStage()
              .find('Transformer')[0]
              .detach()
            option.canvasObj.stage
              .getStage()
              .find('Transformer')[1]
              .detach()
          }
        })
      })
    },
    redo() {
      if (this.redoList.length === 0) return
      const lastPath = this.redoList.pop()
      this.undoList.push(lastPath)
      this.lines = JSON.parse(JSON.stringify(lastPath.lines))
      this.rects = JSON.parse(JSON.stringify(lastPath.rects))
      this.arrows = JSON.parse(JSON.stringify(lastPath.arrows))
      this.rings = JSON.parse(JSON.stringify(lastPath.rings))
      this.texts = JSON.parse(JSON.stringify(lastPath.texts))
    },
    applySharpen() {
      this.undoList.push({
        lines: JSON.parse(JSON.stringify(this.lines)),
        rects: JSON.parse(JSON.stringify(this.rects)),
        arrows: JSON.parse(JSON.stringify(this.arrows)),
        rings: JSON.parse(JSON.stringify(this.rings)),
        texts: JSON.parse(JSON.stringify(this.texts))
      })
      this.redoList = []
      this.canvasObj = null
      if (this.platteType == 'text' && this.texts.length) {
        this.selectData = { data: this.texts[this.texts.length - 1], type: 'text', index: this.texts.length - 1 }
      } else if (this.platteType == 'pencil' || (this.platteType == 'line' && this.lines.length)) {
        this.selectData = { data: this.lines[this.lines.length - 1], type: 'line', index: this.lines.length - 1 }
      } else if (this.platteType == 'rect' && this.rects.length) {
        this.selectData = { data: this.rects[this.rects.length - 1], type: 'rect', index: this.rects.length - 1 }
      } else if (this.platteType == 'arrow' && this.arrows.length) {
        this.selectData = { data: this.arrows[this.arrows.length - 1], type: 'arrow', index: this.arrows.length - 1 }
      } else if (this.platteType == 'ring' && this.rings.length) {
        this.selectData = { data: this.rings[this.rings.length - 1], type: 'ring', index: this.rings.length - 1 }
      }
    },
    drawPaths(paths) {
      paths?.forEach(path => {
        path.ctx.putImageData(path.canvasData, 0, 0, 0, 0, path.canvas.offsetWidth, path.canvas.offsetHeight)
      })
    },
    openPlatte() {
      this.$emit('createdCanvas')
      this.platteType = 'rect'
      this.platteValue = ''
      this.platteInjuryType = 0
      this.platteInjuryInjuryReason = undefined
      this.undoList = [
        {
          lines: [],
          rects: [],
          arrows: [],
          rings: [],
          texts: []
        }
      ]
      this.redoList = []
      this.lines = []
      this.rects = []
      this.arrows = []
      this.rings = []
      this.texts = []
      setTimeout(() => {
        if (this.$refs.platteInput) this.$refs.platteInput.EditorOnsize()
      }, 500)
    },
    savePlatte() {
      if (
        !this.platteInjuryType &&
        !this.platteInjuryInjuryReason &&
        !this.rects.length &&
        !this.arrows.length &&
        !this.rings.length &&
        !this.lines.length &&
        !this.texts.length &&
        !this.platteValue
      ) {
        this.closePlatte()
        return
      }
      let arr = [...this.rects, ...this.lines, ...this.arrows, ...this.rings, ...this.texts]
      let sortArrY = []
      let sortArrYTwo = []
      let sortY = 0
      let sortArrX = []
      let sortX = 0
      let endY = 0
      if (arr.length) {
        arr.forEach(a => {
          if (a.data.points) {
            sortArrY.push(a.data.points[1])
          } else {
            sortArrY.push(a.data.y)
          }
          if (a.platteType == 'rect') {
            sortArrX.push(a.data.x + a.data.width)
            sortArrYTwo.push(a.data.y + a.data.height)
          } else if (a.platteType == 'ring') {
            sortArrX.push(a.data.x + a.data.radiusX)
            sortArrYTwo.push(a.data.y + a.data.radiusY)
          } else if (a.platteType == 'text') {
            let texts = []
            let textsTwo = []
            this.showOptions.forEach(showOption => {
              let option = showOption.list.find(option => option.checked)
              if (option.isLatest) {
                if (option.canvasObj.stage.getStage().find('Text').length) {
                  texts = []
                  option.canvasObj.stage
                    .getStage()
                    .find('Text')
                    .forEach(t => {
                      texts.push(t.textWidth.toFixed(0) - 1 + t.attrs.x)
                      textsTwo.push(t.textHeight.toFixed(0) - 1 + t.attrs.y)
                    })
                }
              }
            })
            texts = texts.sort((a, b) => b - a)
            textsTwo = textsTwo.sort((a, b) => b - a)
            sortArrX.push(texts[0])
            sortArrYTwo.push(textsTwo[0])
          } else {
            let arr = []
            let arrTwo = []
            a.data.points.forEach((ap, apIndex) => {
              if (apIndex % 2 == 0) {
                arr.push(ap)
              } else {
                arrTwo.push(ap)
              }
            })
            arr = arr.sort((a, b) => b - a)
            arrTwo = arrTwo.sort((a, b) => b - a)
            sortArrX.push(arr[0] + a.data.x)
            sortArrYTwo.push(arrTwo[0] + a.data.y)
          }
        })
        sortArrY.sort((a, b) => a - b)
        sortY = sortArrY[0]
        sortArrYTwo.sort((a, b) => b - a)
        endY = sortArrYTwo[0]
        sortArrX.sort((a, b) => b - a)
        sortX = sortArrX[0]
      }
      this.noDrag = false
      this.showOptions.forEach((showOption, showOptionIndex) => {
        let stage = this.$refs['canvas-' + showOptionIndex + '-' + showOption.list.findIndex(option => option.checked)]
        if (stage&&stage.length) {
          stage = stage[0]
          stage
            .getStage()
            .find('Transformer')[0]
            .nodes([])
          stage
            .getStage()
            .find('Transformer')[1]
            .detach()
        }
      })
      this.platteLoading = true
      this.$emit('savePlatte', sortY, sortX, endY)
      postAction('user/annotation/style', { style: JSON.stringify(this.platteStyle) })
    },
    closePlatte() {
      if (this.platteLoading) return
      this.platteType = 'rect'
      this.platteValue = ''
      this.platteInjuryType = 0
      this.platteInjuryInjuryReason = undefined
      this.undoList = [
        {
          lines: [],
          rects: [],
          arrows: [],
          rings: [],
          texts: []
        }
      ]
      this.redoList = []
      this.lines = []
      this.rects = []
      this.arrows = []
      this.rings = []
      this.texts = []
      this.noDrag = false
      this.$emit('closePlatte')
    },
    selectPlatte(type) {
      if (this.platteLoading) return
      if (this.platteType == type) {
        this.platteType = ''
      } else {
        this.platteType = type
        if (type == 'arrow') {
          this.minSliderValue = 3
          this.maxSliderValue = 50
          this.sliderValue = this.platteStyle[type].size
          this.platteColor = this.platteStyle[type].color
        } else if (type == 'text') {
          this.minSliderValue = 3
          this.maxSliderValue = 30
          this.sliderValue = this.platteStyle[type].size
          this.platteColor = this.platteStyle[type].color
        } else {
          this.minSliderValue = 3
          this.maxSliderValue = 50
          this.sliderValue = this.platteStyle[type].size
          this.platteColor = this.platteStyle[type].color
        }
      }
    },
    canvasMouseover(canvasObj) {
      if (!canvasObj.drawdown) {
        document.getElementsByClassName('show-img')[0].style.cursor = 'pointer'
      }
    },
    canvasMouseleave(canvasObj) {
      if (!canvasObj.drawdown) {
        document.getElementsByClassName('show-img')[0].style.cursor = 'default'
      }
    },
    transformstart() {
      this.isStartTrans = true
    },
    transformend() {
      this.isStartTrans = false
    },
    isOnCircleEdge(mx, my, h, k, a, b) {
      // 点击坐标与圆形边框的距离0~1，1为圆心
      var leftSide = Math.pow(mx - h, 2) / Math.pow(a, 2) + Math.pow(my - k, 2) / Math.pow(b, 2)
      var tolerance = 0.1 //容差
      return Math.abs(leftSide - 1) <= tolerance
    },
    handleDragStart(e, data, canvasObj, platteType, index, showOptionIndex, showOption) {
      if (platteType == 'rect') {
        if (
          (e.evt.offsetX > e.target.attrs.x && e.evt.offsetX < e.target.attrs.x + 10) ||
          (e.evt.offsetX < e.target.attrs.x + e.target.attrs.width &&
            e.evt.offsetX > e.target.attrs.x + e.target.attrs.width - 10) ||
          (e.evt.offsetY > e.target.attrs.y && e.evt.offsetY < e.target.attrs.y + 10) ||
          (e.evt.offsetY < e.target.attrs.y + e.target.attrs.height &&
            e.evt.offsetY > e.target.attrs.y + e.target.attrs.height - 10)
        ) {
        } else {
          data.data.draggable = false
          return
        }
      } else if (platteType == 'ring') {
        if (
          this.isOnCircleEdge(
            e.evt.offsetX,
            e.evt.offsetY,
            e.target.attrs.x,
            e.target.attrs.y,
            e.target.attrs.radiusX,
            e.target.attrs.radiusY
          )
        ) {
        } else {
          data.data.draggable = false
          return
        }
      }
      this.selectData = {
        data,
        type: platteType,
        index
      }
      if (platteType) {
        let stage = this.$refs[
          'canvas-' + showOptionIndex + '-' + showOption.list.findIndex(option => option.checked)
        ][0]
        if (platteType == 'text') {
          stage
            .getStage()
            .find('Transformer')[0]
            .nodes([e.target])
          stage
            .getStage()
            .find('Transformer')[1]
            .detach()
        } else {
          stage
            .getStage()
            .find('Transformer')[1]
            .nodes([e.target])
          stage
            .getStage()
            .find('Transformer')[0]
            .detach()
        }
      }
      if (!canvasObj.drawdown) {
        /*         if (platteType != 'text') {
          data.data.stroke = 'green'
          if (platteType == 'arrow') {
            data.data.fill = 'green'
          }
        } else {
          data.data.fill = 'green'
        } */
      }
      this.minSliderValue = 3
      this.maxSliderValue = 50
      if (platteType == 'pencil' || platteType == 'line') {
        this.platteType = platteType
        this.sliderValue = data.data.strokeWidth
        this.platteColor = data.data.stroke
      } else if (platteType == 'arrow') {
        this.platteType = platteType
        this.sliderValue = data.data.strokeWidth
        this.platteColor = data.data.fill
      } else if (platteType == 'rect') {
        this.platteType = platteType
        this.sliderValue = data.data.strokeWidth
        this.platteColor = data.data.stroke
      } else if (platteType == 'ring') {
        this.platteType = platteType
        this.sliderValue = data.data.strokeWidth
        this.platteColor = data.data.stroke
      } else if (platteType == 'text') {
        this.platteType = platteType
        this.minSliderValue = 3
        this.maxSliderValue = 30
        this.sliderValue =
          ((data.data.fontSize / showOption.list.find(option => option.checked).num - 1) / 4).toFixed(0) - 0
        this.platteColor = data.data.fill
      }
      this.isDragging = true
    },
    handleDragEnd(e, data, canvasObj, platteType) {
      if ((platteType == 'rect'||platteType == 'ring') && !data.data.draggable) {
        data.data.draggable = true
        this.isDragging = false
        return
      }
      if (!canvasObj.drawdown) {
        if (platteType != 'text') {
          data.data.stroke = this.platteColor
          if (platteType == 'arrow') {
            data.data.fill = this.platteColor
          }
        } else {
          data.data.fill = this.platteColor
        }
      }
      data.data.x = e.target.attrs.x
      this.isDragging = false
    },
    handleMouseDown(event, option, showOptionIndex, showOption) {
      if (this.isDragging) return
      if (this.isStartTrans) return
      let stage = this.$refs['canvas-' + showOptionIndex + '-' + showOption.list.findIndex(option => option.checked)][0]
      stage
        .getStage()
        .find('Transformer')[0]
        .nodes([])
      stage
        .getStage()
        .find('Transformer')[1]
        .detach()
      this.selectData = null
      let e = event.evt
      this.canvasIndex = e.target.parentNode.parentNode.id.split('-')[1]
      if (!this.isAddText) {
        this.canvasObj = this.showOptions[this.canvasIndex].list.find(option => option.checked).canvasObj
      }
      switch (this.platteType) {
        case 'pencil':
          this.canvasObj.drawdown = true
          const newPencil = {
            x: 0,
            y: 0,
            stroke: this.platteColor,
            draggable: true,
            strokeWidth: this.sliderValue * option.num,
            points: [e.offsetX, e.offsetY],
            lineCap: 'round',
            lineJoin: 'round'
          }
          this.lines.push({ data: newPencil, type: option.title, platteType: this.platteType })
          break
        case 'line':
          this.canvasObj.drawdown = true
          const newLine = {
            x: 0,
            y: 0,
            stroke: this.platteColor,
            draggable: true,
            strokeWidth: this.sliderValue * option.num,
            points: [e.offsetX, e.offsetY],
            lineCap: 'round',
            lineJoin: 'round'
          }
          this.lines.push({ data: newLine, type: option.title, platteType: this.platteType })
          break
        case 'arrow':
          this.canvasObj.drawdown = true
          const newArrow = {
            x: 0,
            y: 0,
            points: [e.offsetX, e.offsetY],
            pointerLength: this.sliderValue * 4,
            pointerWidth: this.sliderValue * 4,
            fill: this.platteColor,
            stroke: this.platteColor,
            draggable: true,
            strokeWidth: this.sliderValue * option.num
          }
          this.arrows.push({ data: newArrow, type: option.title, platteType: this.platteType })
          break
        case 'rect':
          this.canvasObj.drawdown = true
          const newRect = {
            x: e.offsetX,
            y: e.offsetY,
            width: 0,
            height: 0,
            stroke: this.platteColor,
            draggable: true,
            strokeWidth: this.sliderValue * option.num
          }
          this.rects.push({ data: newRect, type: option.title, platteType: this.platteType })
          break
        case 'ring':
          this.canvasObj.drawdown = true
          const newRing = {
            x: e.offsetX,
            y: e.offsetY,
            oldX: e.offsetX,
            oldY: e.offsetY,
            radiusX: 0,
            radiusY: 0,
            stroke: this.platteColor,
            strokeWidth: this.sliderValue * option.num,
            draggable: true
          }
          this.rings.push({ data: newRing, type: option.title, platteType: this.platteType })
          break
        case 'text':
          this.canvasObj.drawdown = true
          if (!this.isAddText) {
            this.canvasObj.startPointX = e.offsetX
            this.canvasObj.startPointY = e.offsetY
          }
          break
        default:
          return
      }
    },
    handleMouseMove(event, option) {
      let e = event.evt
      if (!this.canvasObj) return
      let canvasIndex = e.target.parentNode.parentNode.id.split('-')[1]
      switch (this.platteType) {
        case 'pencil':
          if (this.canvasObj.drawdown) {
            if (!this.lines.length) return
            let lastLine = this.lines[this.lines.length - 1]
            lastLine.data.points = lastLine.data.points.concat([e.offsetX, e.offsetY])
          }
          break
        case 'line':
          if (this.canvasObj.drawdown) {
            const lastLine = this.lines[this.lines.length - 1]
            lastLine.data.points = [lastLine.data.points[0], lastLine.data.points[1], e.offsetX, e.offsetY]
          }
          break
        case 'arrow':
          if (this.canvasObj.drawdown) {
            const lastLine = this.arrows[this.arrows.length - 1]
            lastLine.data.points = [lastLine.data.points[0], lastLine.data.points[1], e.offsetX, e.offsetY]
          }
          break
        case 'rect':
          if (this.canvasObj.drawdown) {
            const lastRects = this.rects[this.rects.length - 1]
            lastRects.data.width = e.offsetX - lastRects.data.x
            lastRects.data.height = e.offsetY - lastRects.data.y
          }
          break
        case 'ring':
          if (this.canvasObj.drawdown) {
            const lastRings = this.rings[this.rings.length - 1]
            let changeX =
              e.offsetX - lastRings.data.oldX > 0
                ? (e.offsetX - lastRings.data.oldX) / 2
                : (lastRings.data.oldX - e.offsetX) / 2
            let changeY =
              e.offsetY - lastRings.data.oldY > 0
                ? (e.offsetY - lastRings.data.oldY) / 2
                : (lastRings.data.oldY - e.offsetY) / 2
            lastRings.data.radiusX = changeX
            lastRings.data.radiusY = changeY
            lastRings.data.x =
              e.offsetX - lastRings.data.oldX > 0
                ? lastRings.data.oldX + lastRings.data.radiusX
                : lastRings.data.oldX - lastRings.data.radiusX
            lastRings.data.y =
              e.offsetY - lastRings.data.oldY > 0
                ? lastRings.data.oldY + lastRings.data.radiusY
                : lastRings.data.oldY - lastRings.data.radiusY
          }
          break
        case 'text':
          break
        default:
          return
      }
    },
    textEdit(e, text, showOptionIndex, showOption, index) {
      this.platteType = 'text'
      this.minSliderValue = 3
      this.maxSliderValue = 30
      this.sliderValue =
        ((text.data.fontSize / showOption.list.findIndex(option => option.checked).num - 1) / 4).toFixed(0) - 0
      this.platteColor = text.data.fill
      let newText = JSON.parse(JSON.stringify(text))
      let num = showOption.list.find(option => option.checked).num
      this.sliderValue = ((text.data.fontSize / num - 1) / 4).toFixed(0) - 0
      text.data.opacity = 0
      let stage = this.$refs['canvas-' + showOptionIndex + '-' + showOption.list.findIndex(option => option.checked)][0]
      stage
        .getStage()
        .find('Transformer')[0]
        .nodes([])
      stage
        .getStage()
        .find('Transformer')[1]
        .detach()
      let input = document.getElementById('platteEditDiv').cloneNode(true)
      let canvasArea = document.getElementById('canvas-area-' + showOptionIndex)
      input.id = 'platteEditInput'
      canvasArea.appendChild(input)
      input.style.position = 'absolute'
      input.contenteditable = true
      input.innerText = newText.data.text || ''
      input.style.maxWidth = `max-content`
      input.style.maxHeight = `max-content`
      input.style.minWidth = `54px`
      input.style.minHeight = `24px`
      input.style.left = `${e.target.attrs.x}px`
      input.style.top = `${e.target.attrs.y - 4 - ((this.sliderValue * 4 + 1) * num) / 5}px`
      input.style.color = newText.data.fill
      input.style.fontSize = newText.data.fontSize + 'px'
      input.style.paddingTop = `4px`
      input.style.paddingBottom = `6px`
      input.style.overflow = `hidden`
      input.style.border = '2px dashed red'
      input.style.lineHeight = newText.data.fontSize + 'px'
      input.style.outline = 'none'
      window.getSelection().selectAllChildren(input)
      window.getSelection().collapseToEnd()
      input.addEventListener('input', e => {
        if (
          e.target.childNodes &&
          e.target.childNodes.length &&
          Array.from(e.target.childNodes).some(child => child.tagName == 'IMG')
        ) {
          let child = Array.from(e.target.childNodes).find(child => child.tagName == 'IMG')
          e.target.removeChild(child)
        }
        newText.data.text = e.target.innerText || ''
      })
      input.addEventListener('blur', () => {
        canvasArea.removeChild(input)
        let newPushText = {
          x: e.target.attrs.x,
          y: e.target.attrs.y,
          text: newText.data.text,
          fontSize: newText.data.fontSize,
          draggable: false,
          fontFamily: newText.data.fontFamily,
          fill: newText.data.fill,
          opacity: 1
        }
        const timer = setTimeout(() => {
          newPushText.draggable = true
          clearTimeout(timer)
        }, 0)
        this.texts.splice(index, 1)
        this.texts.push({
          data: newPushText,
          type: showOption.list.find(option => option.checked).title,
          platteType: this.platteType
        })
        this.undoList.push({
          lines: JSON.parse(JSON.stringify(this.lines)),
          rects: JSON.parse(JSON.stringify(this.rects)),
          arrows: JSON.parse(JSON.stringify(this.arrows)),
          rings: JSON.parse(JSON.stringify(this.rings)),
          texts: JSON.parse(JSON.stringify(this.texts))
        })
        this.redoList = []
        this.canvasObj = null
        this.selectData = { data: this.texts[this.texts.length - 1], type: 'text' }
      })
    },
    handleMouseUp(event, option, showOptionIndex, showOption) {
      this.isDragging = false
      let e = event.evt
      let canvasIndex = e.target.parentNode.parentNode.id.split('-')[1]
      if (this.canvasObj) {
        this.canvasObj.isDrag = false
      }
      this.drawdown = false
      if (
        this.platteType == 'arrow' &&
        this.arrows.length &&
        this.arrows[this.arrows.length - 1].data.points.length == 2
      ) {
        this.arrows.pop()
      }
      if (this.platteType == 'text' && this.canvasObj) {
        let stage = this.$refs[
          'canvas-' + showOptionIndex + '-' + showOption.list.findIndex(option => option.checked)
        ][0]
        stage
          .getStage()
          .find('Transformer')[0]
          .detach()
        stage
          .getStage()
          .find('Transformer')[1]
          .detach()
        let fontFamily = 'fontGen Shin Gothic Regular'
        /*         if (this.departName.indexOf('日语部') > -1) {
          fontFamily = 'fontGen Shin Gothic Regular'
        } else if (this.departName.indexOf('韩语部') > -1) {
          fontFamily = 'fontNoto Sans KR Regular'
        } else {
          fontFamily = 'fontfontSource Han Sans CN Regular'
        } */
        // ctx.clearRect(0, 0, 1000, 1000);
        let num = option.num
        let input = document.getElementById('platteEditDiv').cloneNode(true)
        let canvasArea = document.getElementById('canvas-area-' + canvasIndex)
        input.id = 'platteEditInput'
        canvasArea.appendChild(input)
        input.style.position = 'absolute'
        input.contenteditable = true
        input.style.left = `${this.canvasObj.startPointX}px`
        input.style.top = `${this.canvasObj.startPointY - ((this.sliderValue * 4 + 1) * num) / 5}px`
        input.style.maxWidth = `max-content`
        input.style.maxHeight = `max-content`
        input.style.minWidth = `54px`
        input.style.minHeight = `24px`
        input.style.color = this.platteColor
        input.style.fontSize = (this.sliderValue * 4 + 1) * num + 'px'
        input.style.paddingTop = `4px`
        input.style.paddingBottom = `6px`
        input.style.overflow = `hidden`
        input.style.border = '2px dashed red'
        input.style.lineHeight = (this.sliderValue * 4 + 1) * num + 'px'
        input.style.outline = 'none'
        input.focus()
        input.addEventListener('input', e => {
          if (
            e.target.childNodes &&
            e.target.childNodes.length &&
            Array.from(e.target.childNodes).some(child => child.tagName == 'IMG')
          ) {
            let child = Array.from(e.target.childNodes).find(child => child.tagName == 'IMG')
            e.target.removeChild(child)
          }
          this.canvasObj.textvalue = e.target.innerText || ''
        })
        this.isAddText = true
        input.addEventListener('blur', () => {
          canvasArea.removeChild(input)
          this.isAddText = false
          if (this.canvasObj.textvalue) {
            const newText = {
              x: this.canvasObj.startPointX,
              y: this.canvasObj.startPointY + 4,
              text: this.canvasObj.textvalue,
              fontSize: (this.sliderValue * 4 + 1) * num,
              draggable: true,
              fontFamily,
              fill: this.platteColor,
              opacity: 1
            }
            this.canvasObj.textvalue = ''
            this.texts.push({ data: newText, type: option.title, platteType: this.platteType })
            this.applySharpen()
          } else {
            this.canvasObj.drawdown = false
            this.canvasObj = null
          }
        })
        this.canvasObj.drawdown = false
      } else if (this.platteType != 'text' && this.canvasObj) {
        this.applySharpen()
      }
    },
    platteMouseup(e) {
      this.platteDrag = false
    },
    platteMouseDown(e) {
      if (this.noDrag || (!this.toStagesAnnotation.length && this.taskDetail.departName.indexOf('原创部') == -1)) return
      this.platteDrag = true
      this.clientX = e.clientX
    },
    saveScrollTop: simpleDebounce(async function() {
      this.saveScrollTopSim()
    }, 200),
    saveScrollTopSim() {
      this.$emit('saveScrollTop')
    },
    showImgScroll(e, showOption, showOptionIndex) {
      if (this.selectedPlatte || this.isChangePageList) {
        if (showOption.list.find(option => option.checked)) {
          e.target.scrollTop = showOption.list.find(option => option.checked).scrollTop
        }
        return
      }
      if (this.isChangePage) return
      if (this.isToDiff) {
        let option = showOption.list.find(option => option.checked)
        option.scrollTop = e.target.scrollTop
        return
      }
      if (!this.scrollSync) {
        if (showOption.list.find(option => option.checked)) {
          showOption.list.find(option => option.checked).scrollTop = e.target.scrollTop
          if (showOption.list.find(option => option.checked).num == 1) {
            showOption.list.find(option => option.checked).oldScroll = e.target.scrollTop
          }
        }
      } else {
        let option = showOption.list.find(option => option.checked)
        if (option.scrollTop < 0) {
          option.scrollTop = 0
        }
        let changeTop = (e.target.scrollTop - option.scrollTop).toFixed(0) - 0
        option.scrollTop = e.target.scrollTop
        if (option.num == 1) {
          option.oldScroll = e.target.scrollTop
        }
        if (this.checkSyncTabs.some(title => title == option.title)) {
          this.showOptions.forEach((s, sIdx) => {
            if (
              s.list.find(op => op.checked && this.checkSyncTabs.some(title => title == op.title)) &&
              option.title != s.list.find(op => op.checked).title
            ) {
              let dom = document.getElementById('show-img-bottom-' + sIdx)
              s.list.find(op => op.checked && this.checkSyncTabs.some(title => title == op.title)).scrollTop =
                dom.scrollTop - 0 + changeTop
              dom.scrollTop = dom.scrollTop - 0 + changeTop
              if (s.list.find(op => op.checked && this.checkSyncTabs.some(title => title == op.title)).num == 1)
                s.list.find(op => op.checked && this.checkSyncTabs.some(title => title == op.title)).oldScroll =
                  dom.scrollTop + changeTop
            }
          })
        }
      }
      this.$emit('syncChangeScroll', { e, showOptionIndex })
      /*       if (!showOptionIndex) {
        let idx = 0
        this.showImgLeftListPostions.forEach((item, index) => {
          if (e.target.scrollTop >= item.top - 153) {
            idx = index
          }
        })
        this.$emit('changeSelectIdx', idx)
      } */
      this.saveScrollTop()
    },
    dragend(e, value) {
      this.dragOptionValue = value
      this.isDragOption = false
    },
    dragenter() {
      this.isDragOption = true
    },
    dragleave() {
      this.isDragOption = false
    },
    handleDrop() {
      let timeOut = setTimeout(() => {
        if (!this.dragOptionValue) return
        this.$emit('pushOption', this.dragOptionValue)
        this.dragOptionValue = ''
        clearTimeout(timeOut)
      }, 10)
    },
    handleDropTop(e, showOptionIndex, list) {
      let timeOut = setTimeout(() => {
        if (!this.dragOptionValue) return
        let exchangeValue = ''
        let targetIndex = -1
        if (e.target.id && e.target.className.indexOf('show-option') > -1) {
          exchangeValue = e.target.id
          targetIndex = list.findIndex(o => o.value == exchangeValue)
        }
        this.$emit('moveOption', { showOptionIndex, value: this.dragOptionValue, exchangeValue, targetIndex })
        this.dragOptionValue = ''
        clearTimeout(timeOut)
      }, 10)
    },
    changeOption(value) {
      this.$emit('changeOption', value)
    },
    rightClick(color) {
      this.backgroundColor = color
    }
  }
}
</script>
<style scoped lang="less">
.show-img-top-width {
  position: absolute;
  right: 30px;
}
/* .show-page-img-blur {
  filter: blur(3px);
} */
.delete-border {
  position: absolute;
  border: 2px dashed red;
  z-index: 99;
  > div {
    position: absolute;
    width: 22px;
    height: 22px;
    top: -22px;
    right: -22px;
    text-align: center;
    line-height: 22px;
    background: red;
  }
}
.theme-color {
  background-color: var(--theme-color) !important;
}
.mask {
  position: absolute;
  background: #409eff;
  opacity: 0.4;
  z-index: 999;
  transition: none !important;
}
.stroke-span-hasMark::after {
  width: var(--mFontSize) !important;
  height: var(--mFontSize) !important;
  background-color: var(--mBack);
  display: inline-block;
  position: absolute;
  user-select: none;
  white-space: nowrap;
  bottom: var(--mBottom) !important;
  left: var(--mLeft) !important;
  border-radius: var(--mBorderRadius) !important;
}
.stroke-span::after {
  content: attr(data-text);
  position: absolute;
  z-index: 0;
  white-space: nowrap;
  left: var(--pLeft);
  top: var(--pTop);
  -webkit-text-stroke: 0;
  text-align: center;
  font-size: var(--pFontSize) !important;
  /* line-height: normal; */
  transform: var(--transform);
  font-weight: var(--bold);
  font-style: var(--isIncline);
  letter-spacing: var(--letterSpacing);
  height: var(--pLineHeightH);
  width: var(--pLineHeightW);
  user-select: none;
  display: inline-block;
  -webkit-text-stroke: var(--stroke);
}
.stroke-span::before {
  content: attr(data-text);
  position: absolute;
  z-index: 1;
  left: var(--pLeft);
  top: var(--pTop);
  -webkit-text-stroke: 0;
  text-align: center;
  font-size: var(--pFontSize) !important;
  /* line-height: normal; */
  transform: var(--transform);
  font-weight: var(--bold);
  font-style: var(--isIncline);
  letter-spacing: var(--letterSpacing);
  height: var(--pLineHeightH);
  width: var(--pLineHeightW);
  user-select: none;
  display: inline-block;
}
.stroke-span-child {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  z-index: 1;
}
.stroke-span-child::after {
  content: attr(data-text);
  position: absolute;
  z-index: 0;
  left: 0px;
  top: 0px;
  -webkit-text-stroke: 0;
}
.stroke-span {
  position: relative;
  z-index: 1000;
}
.stroke-span-phonetic::after {
  content: attr(data-text);
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  -webkit-text-stroke: 0;
}
.stroke-span-phonetic {
  position: relative;
  left: 0;
  top: 0;
  z-index: 0;
}
.right-text-input {
  white-space: nowrap;
  min-width: max-content;
  min-height: max-content;
  span {
    text-align: center;
    /* display: inline-block; */
    position: relative;
    > .mark {
      line-height: 0;
      position: absolute;
      width: 100%;
      text-align: center !important;
      transform: scale(0.2);
      -webkit-font-smoothing: antialiased;
      font-weight: 700;
      bottom: 3px;
      backface-visibility: hidden;
    }
  }
}
.diff-div {
  position: absolute;
  border: 2px solid orange;
}
.diff-div-select {
  position: absolute;
  border: 2px solid red;
}
.change-show-img-width {
  cursor: e-resize;
}
.show-img-bottom-local {
  cursor: url('../../../assets/本地_local-two.svg'), default;
}
.show-img-bottom-disabled {
  cursor: no-drop;
}
.diff-model {
  z-index: 1055;
  position: absolute;
  top: 0;
  left: 0;
  width: 480px;
  height: max-content;
  background-color: #fff;
  box-shadow: 2px 1px 6px 2px rgba(0, 0, 0, 0.2);
  .diff-model-title {
    font-size: 14px;
    font-weight: 400;
    padding: 12px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
  .diff-model-body {
    display: flex;
    justify-content: space-between;
    padding: 12px 24px 12px 24px;
    > div {
      width: 50%;
      > div {
        display: flex;
        > div {
          width: 50%;
          line-height: 32px;
        }
      }
    }
  }
  .diff-model-footer {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: end;
    align-items: center;
    height: 46px;
    padding: 12px;
  }
}
.show-img-top::-webkit-scrollbar-button {
  height: 0;
  width: 0;
}
.show-img-top::-webkit-scrollbar {
  border-radius: 0;
  position: relative;
  width: 3px;
  height: 0;
}
.show-img-top:-webkit-scrollbar-track {
  border-radius: 0;
  background-color: #fff;
}
.show-img-top::-webkit-scrollbar-track-piece {
  border-radius: 0;
}
.show-img-top::-webkit-scrollbar-thumb {
  border-radius: 0;
  background-color: #c1c1c1;
}
.show-img {
  width: 100%;
  height: 100%;
  background-color: #f0f2f5;
  display: flex;
  .show-img-content {
    height: 100%;
    width: 100%;
    display: flex;
    .show-img-content-item {
      height: 100%;
      margin-right: 3px;
      position: relative;
      .show-img-content-item-change-width {
        position: absolute;
        top: 0;
        right: -3px;
        width: 3px;
        height: 100%;
        cursor: e-resize;
        transition: 0.3s background-color;
        &:hover {
          background-color: var(--theme-color);
        }
      }
      .show-img-top {
        height: 30px;
        overflow-x: auto;
        overflow-y: hidden;
        width: 100%;
        position: relative;
        .show-img-top-scroll {
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: max-content;
          padding-left: 14px;
          padding-right: 14px;
          /* margin: 0 auto; */
        }
        .show-option {
          padding: 2px 8px 2px 8px;
          background-color: #f0f2f5;
          border-radius: 5px;
          margin-right: 5px;
          line-height: 20px;
          display: inline-block;
          font-size: 14px;
          height: 25px;
          transition: 0.3s all;
          width: max-content;
          cursor: pointer;
          &:hover {
            color: var(--theme-color);
          }
        }
        .show-option-select {
          background-color: #f0f2f5;
          color: var(--theme-color);
          border-radius: 5px;
        }
        .show-img-top-right {
          width: 18px;
          position: absolute;
          background-color: #fff;
          top: 0;
          height: 30px;
          right: 0px;
          > div {
            padding-left: 3px;
            padding-right: 3px;
            background-color: #fff;
            border-radius: 4px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            width: 18px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
      .show-img-bottom {
        height: calc(100% - 30px);
        margin: 0px 10px;
        overflow: auto;
        position: relative;
        display: flex;
        /* justify-content: center; */
        z-index: 0;
        .show-content {
          height: 100%;
          position: absolute;
          top: 0;
          width: 100%;
        }
        .canvas-content {
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
        &::-webkit-scrollbar-button {
          height: 0;
          width: 0;
        }
        &::-webkit-scrollbar {
          border-radius: 0;
          position: relative;
          width: 5px;
          height: 15px;
        }
        &::-webkit-scrollbar-track {
          border-radius: 0;
          background: #fff;
        }
        &::-webkit-scrollbar-track-piece {
          border-radius: 0;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 0;
          background-color: #c1c1c1;
        }
      }
    }
    .push-option {
      padding-left: 4px;
      > div {
        width: 58px;
        padding: 8px 16px;
        font-size: 16px;
        letter-spacing: 5px;
        writing-mode: vertical-rl;
        border: 1px dashed rgba(0, 0, 0, 0.65);
        transition: 0.3s all;
        &:hover {
          color: var(--theme-color);
          border-color: var(--theme-color);
        }
      }
    }
    .push-option-color {
      > div {
        color: var(--theme-color);
        border-color: var(--theme-color);
      }
    }
  }
  /deep/ .platte {
    z-index: 1055;
    position: absolute;
    top: 0;
    left: 0;
    width: 590px;
    .platte-select {
      width: 300px;
      height: 35px;
      margin-bottom: 5px;
      border-radius: 4px;
      padding: 5px;
      background-color: #fff;
      box-shadow: 2px 1px 6px 2px rgba(0, 0, 0, 0.2);
      display: flex;
      align-items: center;
      .ant-select-selection {
        border: none !important;
        box-shadow: none !important;
      }
    }
    .platte-top {
      width: 100%;
      height: 35px;
      margin-top: 5px;
      border-radius: 4px;
      padding: 5px;
      background-color: #fff;
      box-shadow: 2px 1px 6px 2px rgba(0, 0, 0, 0.2);
      display: flex;
      > div {
        display: flex;
        width: 350px;
      }
      .platte-top-right {
        flex: 1;
        height: 100%;
        > div {
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          height: 100%;
          > div {
            width: 15px;
            height: 15px;
          }
          .platte-top-color-item {
            cursor: pointer;
            box-sizing: 'border-box';
            &:hover {
              box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
            }
          }
          .platte-top-color-item-selected {
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
          }
        }
      }
      .platte-top-left {
        width: 38%;
        > div {
          display: flex;
          .platte-top-label {
            width: 32px;
            font-size: 12px;
            height: 25px;
            line-height: 25px;
            text-align: right;
          }
          .platte-top-slider {
            flex: 1;
            margin-left: 5px;
            > div {
              margin: 0;
              margin-top: 7px;
            }
          }
        }
      }
    }
    .platte-content {
      border-radius: 4px;
      background-color: #fff;
      box-shadow: 2px 1px 6px 2px rgba(0, 0, 0, 0.2);
      .platte-content-top {
        height: 45px;
        display: flex;
        .platte-option {
          width: 45px;
          height: 45px;
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            &:hover {
              color: var(--theme-color);
            }
          }
        }
        .platte-error {
          width: 35px;
          height: 22px;
          font-size: 16px;
          align-items: center;
          justify-content: center;
          display: flex;
          position: relative;
          > span {
            padding: 3px;
            border-radius: 4px;
            position: relative;
            display: inline-block;
            line-height: 15px;
            width: 22px;
            height: 22px;
            cursor: pointer;
            &:hover {
              background-color: rgba(109, 109, 109, 0.1);
            }
          }
          .anticon-check-circle {
            position: absolute;
            top: -4px;
            right: -4px;
            font-size: 10px;
            color: var(--theme-color);
          }
        }
        .platte-error-select {
          > span {
            background-color: rgba(109, 109, 109, 0.1);
          }
        }
        .platte-action {
          width: 40px;
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          &:hover {
            color: var(--theme-color);
          }
        }
      }
    }
  }
}
.ocr-show-item-title-local {
  position: absolute;
  width: 100%;
  height: 18px;
  top: -20px;
  right: -12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div {
    height: 14px;
    width: 14px;
    font-size: 10px;
    border-radius: 7px;
    background-color: var(--theme-color);
    color: #fff;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.img-border {
  position: absolute;
  height: 1px;
  width: 100%;
  background-image: linear-gradient(to right, orange 0%, orange 70%, transparent 50%);
  bottom: 0;
  background-size: 10px 1px;
  background-repeat: repeat-x;
}
/deep/ .local-content {
  position: absolute;
  /*  top: calc(100% + 12px); */
  left: 0;
  width: 330px !important;
  z-index: 99;
  background: #fff;
  border-radius: 5px;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.2);
  .local-content-to-stage {
    padding: 5px 0 5px 12px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
  }
  .local-content-list {
    padding: 5px;
    width: 330px;
    .local-content-error-title {
      padding: 5px 12px 0 12px;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 3px;
    }
    .local-content-error-select {
      padding: 0 12px 0 2px;
      .ant-select-selection {
        border: none !important;
        box-shadow: none !important;
      }
      > div {
        .platte-error {
          width: 35px;
          height: 35px;
          font-size: 14px;
          align-items: center;
          justify-content: center;
          display: flex;
          position: relative;
          > span {
            padding: 3px;
            border-radius: 4px;
            position: relative;
            display: inline-block;
            line-height: 15px;
            width: 22px;
            height: 22px;
            cursor: pointer;
            &:hover {
              background-color: rgba(109, 109, 109, 0.1);
            }
          }
          .anticon-check-circle {
            position: absolute;
            top: -4px;
            right: -4px;
            font-size: 10px;
            color: var(--theme-color);
          }
        }
        .platte-error-select {
          > span {
            background-color: rgba(109, 109, 109, 0.1);
          }
        }
      }
    }
    .local-content-error-list {
      padding: 0 12px 5px 12px;
      max-height: 200px;
      overflow-y: auto;
      .local-content-error-list-item {
        .local-content-error-list-item-top,
        .local-content-error-list-item-bottom {
          display: flex;
        }
        .local-content-error-list-item-top {
          .local-content-error-list-item-top-left {
            width: 30px;
            align-items: center;
            display: flex;
            align-items: center;
            img {
              width: 20px;
              height: 20px;
              border-radius: 50%;
            }
            .local-content-error-list-item-top-right {
              flex: 1;
            }
          }
        }
        .local-content-error-list-item-bottom {
          .local-content-error-list-item-bottom-left {
            width: 30px;
          }
          .local-content-error-list-item-bottom-right {
            flex: 1;
          }
        }
      }
      &::-webkit-scrollbar-button {
        height: 0;
      }
      &::-webkit-scrollbar {
        border-radius: 0;
        position: relative;
        width: 5px;
      }
      &::-webkit-scrollbar-track {
        border-radius: 0;
        background: #fff;
      }
      &::-webkit-scrollbar-track-piece {
        border-radius: 0;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 0;
        background-color: #c1c1c1;
      }
    }
  }
}
.diff-scroll {
  max-height: 320px;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar-button {
    height: 0;
  }
  &::-webkit-scrollbar {
    border-radius: 0;
    position: relative;
    width: 3px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 0;
    background: #fff;
  }
  &::-webkit-scrollbar-track-piece {
    border-radius: 0;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 0;
    background-color: #c1c1c1;
  }
}
.local-content-old {
  position: absolute;
  /*  top: calc(100% + 12px); */
  left: 0;
  width: 265px;
  z-index: 99;
  background: #fff;
  border-radius: 5px;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.2);
  .local-content-list {
    padding: 5px;
    width: 265px;
    .local-content-item {
      .local-content-item-top {
        height: 36px;
        display: flex;
        .local-content-item-top-left {
          width: 20px;
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 20px;
            height: 20px;
            border-radius: 50%;
          }
        }
        .local-content-item-top-right {
          font-size: 12px;
          margin-left: 5px;
          > div {
            line-height: 18px;
          }
        }
      }
      .local-content-item-bottom {
        white-space: pre-line;
        width: 100%;
        word-wrap: break-word;
        font-size: 14px;
        line-height: 25px;
        padding-left: 25px;
        color: black;
      }
    }
  }
}
img {
  -webkit-user-drag: none; /* Chrome, Safari, Opera */
  user-select: none; /* Standard syntax */
  -ms-user-select: none; /* IE10+ */
  -moz-user-select: none; /* Firefox */
  -khtml-user-select: none;
}
</style>
